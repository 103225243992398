import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../../../UI/Button';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faIdCard } from '@fortawesome/free-solid-svg-icons';
import UserInfoUI from '../../../UI/UserInfo';

const QUESTION_LEN = 200;
const ANSWER_LEN = 45;

function EditQuestionUI({
    isLoading,
    onCancelClick,
    onContinueClick,
    onDeleteClick,
    workingQuestion = null,
    problems,
}) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-edit-question': true,
    });

    const [question, setQuestion] = useState(undefined);
    const [answer1, setAnswer1] = useState(undefined);
    const [answer2, setAnswer2] = useState(undefined);
    const [answer3, setAnswer3] = useState(undefined);
    const [answer4, setAnswer4] = useState(undefined);
    const [level, setLevel] = useState(undefined);
    const [rightAnswer, setRightAnswer] = useState(undefined);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (workingQuestion) {
            setQuestion(workingQuestion.question);
            setAnswer1(workingQuestion.answer_1);
            setAnswer2(workingQuestion.answer_2);
            setAnswer3(workingQuestion.answer_3);
            setAnswer4(workingQuestion.answer_4);
            setLevel(workingQuestion.level);
            setRightAnswer(workingQuestion.right_answer);
        }
    }, [workingQuestion]);

    const questionCallback = useCallback((event) => {
        setError(false);
        setQuestion(event.target.value);
    }, []);

    const answer1Callback = useCallback((event) => {
        setError(false);
        setAnswer1(event.target.value);
    }, []);
    const answer2Callback = useCallback((event) => {
        setError(false);
        setAnswer2(event.target.value);
    }, []);
    const answer3Callback = useCallback((event) => {
        setError(false);
        setAnswer3(event.target.value);
    }, []);
    const answer4Callback = useCallback((event) => {
        setError(false);
        setAnswer4(event.target.value);
    }, []);

    const setAnswer = useCallback((value) => {
        setError(false);
        setRightAnswer(value);
    }, []);

    const readyCallback = useCallback(() => {
        if (question && answer1 && answer2 && answer3 && answer4 && level && rightAnswer) {
            onContinueClick({
                question,
                answer1,
                answer2,
                answer3,
                answer4,
                level,
                rightAnswer,
            });
        } else {
            if (!question) {
                setError('Введите вопрос');
            } else if (!answer1 || !answer2 || !answer3 || !answer4) {
                setError('Введите варианты ответов');
            } else if (!rightAnswer) {
                setError('Укажите верный ответ в чекбоксе');
            } else if (!level) {
                setError('Укажите сложность вопроса');
            } else {
                setError('Заполните все поля');
            }
        }
    }, [onContinueClick, question, answer4, answer3, answer2, answer1, level, rightAnswer]);

    const renderForm = () => {
        return (
            <>
                {workingQuestion && workingQuestion.user ? (
                    <div className="question-user-info">
                        <UserInfoUI
                            photoUrl={workingQuestion.user.photo}
                            level={workingQuestion.user.level}
                            type={UserInfoUI.TYPE.SMALL}
                        />
                        <div className="user">
                            <span className="name">
                                {workingQuestion.user.first_name} {workingQuestion.user.last_name}
                            </span>
                            <span className="id">
                                <FontAwesomeIcon icon={faIdCard} />
                                {workingQuestion.user.id},{' '}
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                {workingQuestion.user.questions_add}{' '}
                            </span>
                        </div>
                    </div>
                ) : null}

                <div className="error">{error ? <span>{error}</span> : null}</div>
                <div className="question-block">
                    <textarea
                        maxLength={QUESTION_LEN}
                        className="question"
                        onChange={questionCallback}
                        placeholder="Вопрос"
                        tabIndex={1}
                        value={question}
                    />
                </div>
                <div className="answers">
                    <div className="answer">
                        <span>А</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 1}
                            onChange={() => {
                                setAnswer(1);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer1Callback}
                            value={answer1}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 1 })}
                            tabIndex={2}
                        />
                    </div>
                    <div className="answer">
                        <span>Б</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 2}
                            onChange={() => {
                                setAnswer(2);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer2Callback}
                            value={answer2}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 2 })}
                            tabIndex={3}
                        />
                    </div>
                    <div className="answer">
                        <span>В</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 3}
                            onChange={() => {
                                setAnswer(3);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer3Callback}
                            value={answer3}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 3 })}
                            tabIndex={4}
                        />
                    </div>
                    <div className="answer">
                        <span>Г</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 4}
                            onChange={() => {
                                setAnswer(4);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer4Callback}
                            value={answer4}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 4 })}
                            tabIndex={5}
                        />
                    </div>
                </div>
                <div className="level">
                    <p>Выберите сложность</p>
                    <div className="items">
                        <div
                            className={classNames({ item: true, selected: level === 'school' })}
                            onClick={() => {
                                setError(false);
                                setLevel('school');
                            }}
                        >
                            Школьный
                        </div>
                        <div
                            className={classNames({ item: true, selected: level === 'simple' })}
                            onClick={() => {
                                setError(false);
                                setLevel('simple');
                            }}
                        >
                            Просто
                        </div>
                        <div
                            className={classNames({ item: true, selected: level === 'medium' })}
                            onClick={() => {
                                setError(false);
                                setLevel('medium');
                            }}
                        >
                            Средне
                        </div>
                        <div
                            className={classNames({ item: true, selected: level === 'strong' })}
                            onClick={() => {
                                setError(false);
                                setLevel('strong');
                            }}
                        >
                            Сложно
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderManagementButtons = () => {
        return (
            <div className="management">
                <div className="action-buttons">
                    <Button
                        text={'Сохранить'}
                        onClick={() => {
                            readyCallback();
                        }}
                        isLoading={isLoading}
                    />
                    <Button
                        className={'delete'}
                        text={'Удалить'}
                        onClick={() => {
                            onDeleteClick();
                        }}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        );
    };

    const renderProblems = () => {
        return problems.map((entry) => {
            return (
                <div key={entry.id} className="problem">
                    <div className="user">
                        <UserInfoUI
                            photoUrl={entry.user.photo}
                            level={entry.user.level}
                            type={UserInfoUI.TYPE.SMALL}
                        />
                    </div>
                    <div className="message">
                        <div className="userName">
                            {entry.user.firstName} {entry.user.lastName}
                        </div>
                        <div className="text">{entry.message}</div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={classes}>
            <h2>Редактирование вопроса #{workingQuestion && workingQuestion.id}</h2>

            <div className="content">{renderForm()}</div>
            {workingQuestion ? <div className="problems">{renderProblems()}</div> : null}
            <div className={'buttons'}>
                {renderManagementButtons()}
                <div className="sub-button" onClick={onCancelClick}>
                    Отмена
                </div>
            </div>
        </div>
    );
}

EditQuestionUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    workingQuestion: PropTypes.object,
    problems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            message: PropTypes.string,
            user: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                photo: PropTypes.string,
            }),
        })
    ),
};

export default EditQuestionUI;
