import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';

function NoGamesUI() {
    return (
        <div className="no-games">
            <i className="icon">
                <FontAwesomeIcon icon={faFrown} />
            </i>
            <div className="text">Игр еще не было</div>
        </div>
    );
}

export default NoGamesUI;
