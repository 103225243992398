import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import I18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';

const FooterUI = function (props) {
    return (
        <div id="footer-block">
            <div className="menu">
                {props.groupUrl ? (
                    <a
                        href={props.groupUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group"
                    >
                        <i className="icon">
                            <FontAwesomeIcon icon={faUsers} />
                        </i>
                        Группа игры
                    </a>
                ) : null}
                {props.iosLink ? (
                    <a href={props.iosLink} target={'_blank'} rel="noopener noreferrer">
                        <i className="icon">
                            <FontAwesomeIcon icon={faApple} />
                        </i>
                        Скачать для iPhone
                    </a>
                ) : null}
                {props.androidLink ? (
                    <a href={props.androidLink} target={'_blank'} rel="noopener noreferrer">
                        <i className="icon">
                            <FontAwesomeIcon icon={faAndroid} />
                        </i>
                        Скачать для Android
                    </a>
                ) : null}
            </div>
            <div className="copyright">
                {I18n.t('gameName')} © 2014-{new Date().getFullYear()}
            </div>
        </div>
    );
};

FooterUI.propTypes = {
    groupUrl: PropTypes.string.isRequired,
    iosLink: PropTypes.string,
    androidLink: PropTypes.string,
};

export default FooterUI;
