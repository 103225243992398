import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function Loading(props) {
    return (
        <div className="loading-block">
            <div className="loading-wrapper">
                <div className="lds-ellipsis">
                    <div style={{ backgroundColor: props.color }}></div>
                    <div style={{ backgroundColor: props.color }}></div>
                    <div style={{ backgroundColor: props.color }}></div>
                    <div style={{ backgroundColor: props.color }}></div>
                </div>
            </div>
            {props.loadingText ? <div className="loading-text">{props.loadingText}</div> : null}
        </div>
    );
}

Loading.propTypes = {
    loadingText: PropTypes.string,
};

export default Loading;
