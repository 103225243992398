import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '../../../Loading';
import Button from '../../../UI/Button';
import './style.scss';

function DialogSeeOwnQuestionsUI({
    isLoading,
    isAppendLoading,
    onCancelClick,
    questions,
    onChangeClick,
    onScroll,
    menu,
}) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-see-questions': true,
    });

    const questionList = useMemo(() => {
        return questions.map((question) => {
            //const disabled = question.is_active === 'N' && question.is_checked === 'Y';

            return (
                <div key={question.id} className="question">
                    <div className="title-block">
                        <span className="title">{question.question}</span>
                        <span className="action">
                            <Button
                                className={'smaller-inline'}
                                text={'Изменить'}
                                onClick={onChangeClick.bind(null, question)}
                            />
                        </span>
                    </div>
                    <div className="sub-info">
                        <span>№ {question.id}</span>
                        <span>
                            В игре:{' '}
                            {question.is_active === 'Y' ? (
                                <i className="yes">Да</i>
                            ) : (
                                <i className="no">Нет</i>
                            )}
                        </span>
                        <span>
                            Проверен:{' '}
                            {question.is_checked === 'Y' ? (
                                <i className="yes">Да</i>
                            ) : (
                                <i className="no">Нет</i>
                            )}
                        </span>
                        <span>Лайков: {question.likes}</span>
                        <span>Дизлайков: {question.dislikes}</span>
                    </div>
                </div>
            );
        });
    }, [questions, onChangeClick]);

    return (
        <div className={classes}>
            <h2>Ваши вопросы</h2>

            <div className="content">
                {isLoading ? (
                    <div>Загрузка вопросов...</div>
                ) : (
                    <div>
                        <div className={'filter-menu'}>
                            {menu.map((item, index) => (
                                <span
                                    className={classNames({
                                        item: true,
                                        'is-active': item.isActive,
                                    })}
                                    key={index}
                                    onClick={item.onClick}
                                >
                                    {item.label}
                                </span>
                            ))}
                        </div>
                        <div className={'questions'} onScroll={onScroll}>
                            {questions.length ? questionList : null}
                        </div>
                    </div>
                )}
            </div>

            <div className={'buttons'}>
                {isAppendLoading ? <Loading color={'#ccc'} /> : null}
                <div className="sub-button" onClick={onCancelClick}>
                    Закрыть
                </div>
            </div>
        </div>
    );
}

DialogSeeOwnQuestionsUI.propTypes = {
    isLoading: PropTypes.bool,
    isAppendLoading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onChangeClick: PropTypes.func,
    onScroll: PropTypes.func,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
            isActive: PropTypes.bool,
        })
    ),
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            question: PropTypes.string,
            is_active: PropTypes.string,
            is_checked: PropTypes.string,
            answer_1: PropTypes.string,
            answer_2: PropTypes.string,
            answer_3: PropTypes.string,
            answer_4: PropTypes.string,
            right_answer: PropTypes.number,
            from_user_id: PropTypes.number,
            likes: PropTypes.number,
            dislikes: PropTypes.number,
        })
    ),
};

export default DialogSeeOwnQuestionsUI;
