import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../../../UI/Button';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faQuestionCircle, faIdCard } from '@fortawesome/free-solid-svg-icons';
import UserInfoUI from '../../../UI/UserInfo';

const QUESTION_LEN = 200;
const ANSWER_LEN = 45;

const getUpperCaseLettersCount = (str) => {
    return str.replace(/[^A-ZА-Я]/g, '').length;
};

const fixFirstLetter = (str) => {
    let newStr = str;

    if (getUpperCaseLettersCount(newStr) > 4) {
        newStr = newStr.toLowerCase();
    }

    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

function AddQuestionUI({
    isLoading,
    onCancelClick,
    onContinueClick,
    isQuestionAdded,
    onAddOneMoreClick,
    workingQuestion = null,
    isManagement = false,
}) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-add-question': true,
        'dialog-management-question': isManagement,
    });

    const [question, setQuestion] = useState(undefined);
    const [answer1, setAnswer1] = useState(undefined);
    const [answer2, setAnswer2] = useState(undefined);
    const [answer3, setAnswer3] = useState(undefined);
    const [answer4, setAnswer4] = useState(undefined);
    const [level, setLevel] = useState(undefined);
    const [rightAnswer, setRightAnswer] = useState(undefined);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (workingQuestion) {
            setQuestion(workingQuestion.question);
            setAnswer1(workingQuestion.answer_1);
            setAnswer2(workingQuestion.answer_2);
            setAnswer3(workingQuestion.answer_3);
            setAnswer4(workingQuestion.answer_4);
            setLevel(workingQuestion.level);
            setRightAnswer(workingQuestion.right_answer);
        }
    }, [workingQuestion]);

    const newQuestion = useCallback(() => {
        setQuestion(undefined);
        setAnswer1(undefined);
        setAnswer2(undefined);
        setAnswer3(undefined);
        setAnswer4(undefined);
        setLevel(undefined);
        setRightAnswer(undefined);

        onAddOneMoreClick();
    }, [onAddOneMoreClick]);

    const questionCallback = useCallback((event) => {
        setError(false);
        setQuestion(event.target.value);
    }, []);

    const answer1Callback = useCallback((event) => {
        setError(false);
        setAnswer1(event.target.value);
    }, []);
    const answer2Callback = useCallback((event) => {
        setError(false);
        setAnswer2(event.target.value);
    }, []);
    const answer3Callback = useCallback((event) => {
        setError(false);
        setAnswer3(event.target.value);
    }, []);
    const answer4Callback = useCallback((event) => {
        setError(false);
        setAnswer4(event.target.value);
    }, []);

    const setAnswer = useCallback((value) => {
        setError(false);
        setRightAnswer(value);
    }, []);

    const readyCallback = useCallback(
        (result) => {
            if (question && answer1 && answer2 && answer3 && answer4 && level && rightAnswer) {
                onContinueClick(
                    {
                        question,
                        answer1,
                        answer2,
                        answer3,
                        answer4,
                        level,
                        rightAnswer,
                    },
                    result
                );
            } else {
                if (!question) {
                    setError('Введите вопрос');
                } else if (!answer1 || !answer2 || !answer3 || !answer4) {
                    setError('Введите варианты ответов');
                } else if (!rightAnswer) {
                    setError('Укажите верный ответ в чекбоксе');
                } else if (!level) {
                    setError('Укажите сложность вопроса');
                } else {
                    setError('Заполните все поля');
                }
            }
        },
        [onContinueClick, question, answer4, answer3, answer2, answer1, level, rightAnswer]
    );

    const onNormalizeAnswers = useCallback(() => {
        setAnswer1(fixFirstLetter(answer1));
        setAnswer2(fixFirstLetter(answer2));
        setAnswer3(fixFirstLetter(answer3));
        setAnswer4(fixFirstLetter(answer4));
    }, [answer4, answer3, answer2, answer1]);

    const onNormalizeQuestion = useCallback(() => {
        let nQuestion = question.trim().toLowerCase();
        nQuestion = nQuestion.replace(/ +(?= )/g, '');
        nQuestion = fixFirstLetter(nQuestion);
        setQuestion(nQuestion);
    }, [question]);

    const onRemoveDoubleSpaces = useCallback(() => {
        setQuestion(question.trim().replace(/ +(?= )/g, ''));
        setAnswer1(answer1.trim().replace(/ +(?= )/g, ''));
        setAnswer2(answer2.trim().replace(/ +(?= )/g, ''));
        setAnswer3(answer3.trim().replace(/ +(?= )/g, ''));
        setAnswer4(answer4.trim().replace(/ +(?= )/g, ''));
    }, [question, answer4, answer3, answer2, answer1]);

    const renderForm = () => {
        return (
            <>
                {isManagement && workingQuestion ? (
                    <div className="question-user-info">
                        <UserInfoUI
                            photoUrl={workingQuestion.user.photo}
                            level={workingQuestion.user.level}
                            type={UserInfoUI.TYPE.SMALL}
                        />
                        <div className="user">
                            <span className="name">
                                {workingQuestion.user.first_name} {workingQuestion.user.last_name}
                            </span>
                            <span className="id">
                                <FontAwesomeIcon icon={faIdCard} />
                                {workingQuestion.user.id},{' '}
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                {workingQuestion.user.questions_add}{' '}
                            </span>
                        </div>
                    </div>
                ) : (
                    <p>
                        Пожалуйста добавляйте только интересные вопросы с короткими вариантами
                        ответов
                    </p>
                )}

                <div className="error">{error ? <span>{error}</span> : null}</div>
                <div className="question-block">
                    <textarea
                        maxLength={QUESTION_LEN}
                        className="question"
                        onChange={questionCallback}
                        placeholder="Введите ваш вопрос"
                        tabIndex={1}
                        value={question}
                    />
                </div>
                <div className="answers">
                    <div className="answer">
                        <span>А</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 1}
                            onChange={() => {
                                setAnswer(1);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer1Callback}
                            value={answer1}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 1 })}
                            tabIndex={2}
                        />
                    </div>
                    <div className="answer">
                        <span>Б</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 2}
                            onChange={() => {
                                setAnswer(2);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer2Callback}
                            value={answer2}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 2 })}
                            tabIndex={3}
                        />
                    </div>
                    <div className="answer">
                        <span>В</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 3}
                            onChange={() => {
                                setAnswer(3);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer3Callback}
                            value={answer3}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 3 })}
                            tabIndex={4}
                        />
                    </div>
                    <div className="answer">
                        <span>Г</span>
                        <input
                            tabIndex={-1}
                            type="checkbox"
                            checked={rightAnswer === 4}
                            onChange={() => {
                                setAnswer(4);
                            }}
                        />
                        <input
                            type="text"
                            onChange={answer4Callback}
                            value={answer4}
                            placeholder="Введите вариант ответа"
                            maxLength={ANSWER_LEN}
                            className={classNames({ selected: rightAnswer === 4 })}
                            tabIndex={5}
                        />
                    </div>
                </div>
                <div className="level">
                    <p>Выберите сложность</p>
                    <div className="items">
                        <div
                            className={classNames({ item: true, selected: level === 'school' })}
                            onClick={() => {
                                setError(false);
                                setLevel('school');
                            }}
                        >
                            Школьный
                        </div>
                        <div
                            className={classNames({ item: true, selected: level === 'simple' })}
                            onClick={() => {
                                setError(false);
                                setLevel('simple');
                            }}
                        >
                            Просто
                        </div>
                        <div
                            className={classNames({ item: true, selected: level === 'medium' })}
                            onClick={() => {
                                setError(false);
                                setLevel('medium');
                            }}
                        >
                            Средне
                        </div>
                        <div
                            className={classNames({ item: true, selected: level === 'strong' })}
                            onClick={() => {
                                setError(false);
                                setLevel('strong');
                            }}
                        >
                            Сложно
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderSuccess = () => {
        return (
            <div className="done">
                <div className="success">
                    <i className="icon">
                        <FontAwesomeIcon icon={faSmile} mask={['far', 'circle']} />
                    </i>
                </div>
                <div className="title">Спасибо за вопрос</div>
                <div className="sub-title">
                    Ваш вопрос отправлен на проверку. <br /> После нее он появится в игре или будет
                    отклонен
                </div>
            </div>
        );
    };

    const renderManagementButtons = () => {
        return (
            <div className="management">
                <div className="fixing-helpers">
                    <div className="sub-button" onClick={onRemoveDoubleSpaces}>
                        Убрать лишние пробелы
                    </div>
                    <div className="sub-button" onClick={onNormalizeAnswers}>
                        Нормализация ответов
                    </div>
                    <div className="sub-button" onClick={onNormalizeQuestion}>
                        Нормализация вопроса
                    </div>
                </div>
                <div className="action-buttons">
                    <Button
                        text={'Сохранить'}
                        onClick={() => {
                            readyCallback(true);
                        }}
                        isLoading={isLoading}
                    />
                    <Button
                        className={'delete'}
                        text={'Удалить'}
                        onClick={() => {
                            readyCallback(false);
                        }}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        );
    };

    const renderAddButtons = () => {
        return (
            <>
                {!isQuestionAdded ? (
                    <Button
                        text={'Отправить вопрос'}
                        onClick={readyCallback}
                        isLoading={isLoading}
                    />
                ) : (
                    <Button
                        text={'Добавить еще вопрос'}
                        onClick={newQuestion}
                        isLoading={isLoading}
                    />
                )}
            </>
        );
    };

    return (
        <div className={classes}>
            <h2>
                {isManagement
                    ? `Редактирование вопроса #${workingQuestion && workingQuestion.id}`
                    : 'Добавление нового вопроса'}
            </h2>

            <div className="content">{!isQuestionAdded ? renderForm() : renderSuccess()}</div>
            <div className={'buttons'}>
                {isManagement ? renderManagementButtons() : renderAddButtons()}
                <div className="sub-button" onClick={onCancelClick}>
                    Отмена
                </div>
            </div>
        </div>
    );
}

AddQuestionUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onAddOneMoreClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isQuestionAdded: PropTypes.bool,
    workingQuestion: PropTypes.object,
    isManagement: PropTypes.bool,
};

export default AddQuestionUI;
