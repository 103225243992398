import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import DialogUserDisconnectedUI from '../../../../components/Dialog/Types/UserDisconnected';
import { URL_HOME } from '../../../../config/url';
import { hideDialog } from '../../../../store/dialog';
import { goToPage } from '../../../../store/app';

export const DIALOG_USER_DISCONNECTED = 'user-disconnected';

class DialogUserDisconnected extends PureComponent {
    constructor(props) {
        super(props);

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    render() {
        return (
            <DialogUserDisconnectedUI
                onContinueClick={this.handleContinueClick}
                onCancelClick={this.handleCancelClick}
            />
        );
    }

    handleContinueClick() {
        if (this.props.onNewGame) {
            this.props.onNewGame();
        }

        this.props.actions.hideDialog();
    }

    handleCancelClick() {
        this.props.actions.hideDialog();
        this.props.actions.goToPage(URL_HOME);
    }
}

DialogUserDisconnected.propTypes = {
    onNewGame: PropTypes.func.isRequired,
};

const mapStateToProps = function (state) {
    return {
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                hideDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogUserDisconnected);
