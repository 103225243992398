import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProgressItemUI(props) {
    return (
        <div className={['progress-item', props.className].join(' ')}>
            <div className="value">
                <i className="icon" src={props.icon}>
                    <FontAwesomeIcon icon={props.icon} />
                </i>

                <span>{props.value}</span>
            </div>
            {props.text ? <div className="text">{props.text}</div> : null}
        </div>
    );
}

ProgressItemUI.propTypes = {
    icon: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string,
    className: PropTypes.string,
};

export default ProgressItemUI;
