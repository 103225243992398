import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import UserModel from '../../../model/user';
import ButtonUI from '../../UI/Button';
import UserInfoUI from '../../UI/UserInfo';
import { faUserPlus, faUsers, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RandomGameIcon from '../../../images/icons/menu/random-game.svg';
import FriendGameIcon from '../../../images/icons/menu/game-with-friend.svg';
import ProfileIcon from '../../../images/icons/menu/profile.svg';
import ShopIcon from '../../../images/icons/menu/shopping-cart.svg';
import RatingIcon from '../../../images/icons/menu/rating.svg';
import IconCoins from '../../../images/icons/coins-bw.svg';

import LogoImage from '../../../images/logo-plain-long.svg';
import i18n from '../../../i18n';

function PageDefaultUI(props) {
    const { user, onlineUsersCount } = props;

    const renderFirstButton = () => {
        if (props.onMoneyClick) {
            return (
                <ButtonUI
                    text={'Кошелек'}
                    className="medium shop"
                    icon={IconCoins}
                    onClick={props.onMoneyClick}
                />
            );
        }

        if (props.onShopPageClick) {
            return (
                <ButtonUI
                    text={'Магазин'}
                    className="medium shop"
                    icon={ShopIcon}
                    onClick={props.onShopPageClick}
                />
            );
        }

        if (props.onGroupClick) {
            return (
                <ButtonUI
                    text={'Вступить'}
                    className="medium join"
                    iconElement={<FontAwesomeIcon icon={faUsers} />}
                    onClick={props.onGroupClick}
                />
            );
        }

        return null;
    };

    return (
        <div id="page-default">
            <div className="app-logo">
                <img src={LogoImage} alt={''} />
            </div>

            <div className="main-block">
                <div className="wrapper">
                    <div className="hello-block">
                        <UserInfoUI photoUrl={user.photoUrl} type={UserInfoUI.TYPE.BIG} />
                        <div className="hello">Привет, {user.firstName}</div>
                    </div>

                    <div className="hello-text">
                        {i18n.t('pages.home.helloText', { count: onlineUsersCount })}
                    </div>

                    <div className="menu main-menu">
                        <ButtonUI
                            text={'Начать играть'}
                            className="medium random"
                            icon={RandomGameIcon}
                            onClick={props.onRandomGamePageClick}
                        />
                        <ButtonUI
                            text={'Играть с другом'}
                            className="medium friend"
                            icon={FriendGameIcon}
                            onClick={props.onFriendGamePageClick}
                        />
                    </div>
                </div>
            </div>

            <div className="menu sub-menu">
                {renderFirstButton()}

                <ButtonUI
                    text={'Рейтинг'}
                    className="medium rating"
                    icon={RatingIcon}
                    onClick={props.onRatingPageClick}
                />

                {props.onInviteClick ? (
                    <ButtonUI
                        text={'Пригласить'}
                        className="medium invite"
                        iconElement={<FontAwesomeIcon icon={faUserPlus} />}
                        onClick={props.onInviteClick}
                    />
                ) : null}

                <ButtonUI
                    text={'Профиль'}
                    className="medium profile"
                    icon={ProfileIcon}
                    onClick={props.onProfilePageClick}
                />

                {props.onHelpClick ? (
                    <ButtonUI
                        text={'Помощь'}
                        className="medium invite"
                        iconElement={<FontAwesomeIcon icon={faQuestionCircle} />}
                        onClick={props.onHelpClick}
                    />
                ) : null}
            </div>
        </div>
    );
}

PageDefaultUI.propTypes = {
    user: PropTypes.instanceOf(UserModel),
    onRatingPageClick: PropTypes.func.isRequired,
    onShopPageClick: PropTypes.func,
    onProfilePageClick: PropTypes.func.isRequired,
    onInviteClick: PropTypes.func.isRequired,
    onFriendGamePageClick: PropTypes.func.isRequired,
    onRandomGamePageClick: PropTypes.func.isRequired,
    onlineUsersCount: PropTypes.number,
    onGroupClick: PropTypes.func,
    onHelpClick: PropTypes.func,
    onMoneyClick: PropTypes.func,
};

export default PageDefaultUI;
