import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function DialogUI(props) {
    return (
        <div id="dialog">
            <TransitionGroup className="dialog-animation">
                <CSSTransition
                    key={props.type}
                    component="div"
                    classNames="dialog"
                    appear={true}
                    exit={true}
                    timeout={300}
                >
                    <div className={'dialog-layout'}>
                        <div className={'dialog'}>{props.children}</div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

DialogUI.propTypes = {
    type: PropTypes.string.isRequired,
};

export default DialogUI;
