import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './style.scss';
import PageCrossUI from '../../../UI/PageCross';
import Button from '../../../UI/Button';
import FriendGameIcon from '../../../../images/icons/menu/game-with-friend.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export const STEP_INTO = 'intro';
export const STEP_NEW_GAME = 'new';
export const STEP_CONNECT = 'connect';

function renderButtons(props) {
    switch (props.step) {
        case STEP_INTO:
            return (
                <React.Fragment>
                    <Button text="Создать новую игру" onClick={props.onNewGameStart} />
                    <Button
                        text="Присоединиться к игре"
                        onClick={props.onGameConnect}
                        className={'connect'}
                    />
                    {props.onFriendInvite ? (
                        <Button
                            text="Пригласить друга"
                            onClick={props.onFriendInvite}
                            className={'invite'}
                        />
                    ) : null}
                </React.Fragment>
            );
        case STEP_NEW_GAME:
        case STEP_CONNECT:
            return (
                <React.Fragment>
                    <Button text="Начать" onClick={props.onStart} isLoading={props.isLoading} />
                    {props.isWaitingFriend && props.onShareLink ? (
                        <Button
                            text="Отправить ссылку"
                            className={'invite'}
                            onClick={props.onShareLink}
                        />
                    ) : null}
                    {!props.isWaitingFriend ? (
                        <div className={'link'} onClick={props.onBackToMenu}>
                            Назад
                        </div>
                    ) : null}
                </React.Fragment>
            );
        default:
    }
}

function renderText(props) {
    switch (props.step) {
        case STEP_INTO:
            return <div>Для начала игры выберите одну из следующих опций</div>;
        case STEP_NEW_GAME:
            return (
                <div>
                    <div className="pin-text">
                        {props.isWaitingFriend
                            ? 'Ожидаем подключения соперника...'
                            : 'Начните игру, cообщите другу код игры и ждите соперника'}
                    </div>
                    <div className="pin">
                        <span className="pin-value">{props.gameCode}</span>
                        <span className="pin-helper">код игры</span>
                    </div>
                </div>
            );
        case STEP_CONNECT:
            return (
                <div>
                    <div className="code-text">Введите код для игры от друга и присоединяйтесь</div>
                    <div className="code">
                        <input
                            autoFocus={true}
                            value={props.gameCode}
                            onChange={props.onCodeChange}
                            minLength={'4'}
                            maxLength={'4'}
                        />
                        {props.errorMessage ? (
                            <div className={'error-message'}>{props.errorMessage}</div>
                        ) : null}
                    </div>
                </div>
            );
        default:
    }
}

const linkInput = React.createRef();
const onCopyClick = () => {
    linkInput.current.select();

    try {
        document.execCommand('copy');
        // eslint-disable-next-line no-alert
        alert('Ссылка скопирована в буфер обмена');
    } catch (err) {
        // no empty
    }
};

function PageGameUI(props) {
    return (
        <div id="page-game-friend" className="page with-background">
            {props.onPageClose ? <PageCrossUI onClick={props.onPageClose} /> : null}
            <h1>Игра с другом</h1>
            <div className="content">
                <div className={'game-friend-block'}>
                    <div className="image">
                        <img src={FriendGameIcon} alt={''} />
                    </div>
                    <div className="info">
                        <div className="text">{renderText(props)}</div>
                        <div className="buttons">{renderButtons(props)}</div>
                        {props.step === STEP_NEW_GAME && props.hasUseLink ? (
                            <div className="game-link">
                                <div className="link-wrapper">
                                    <input
                                        ref={linkInput}
                                        className="link-value"
                                        value={props.friendGameUrl}
                                        readOnly
                                    />
                                    <i onClick={onCopyClick} data-tip data-for="copy-link">
                                        <FontAwesomeIcon icon={faCopy} />
                                        <ReactTooltip
                                            id="copy-link"
                                            place="top"
                                            type="light"
                                            effect="solid"
                                        >
                                            Нажмите что бы скопировать ссылку
                                        </ReactTooltip>
                                    </i>
                                </div>
                                <div className="link-text">
                                    или скопируйте и отправьте ему эту ссылку <br />
                                    после начала игры
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={'helper'}>
                    <span>
                        Приглашайте друзей, рассказывайте друзьям об игре и соревнуйтесь с ними,
                        ведь играть вместе намного интереснее.
                    </span>
                </div>
            </div>
        </div>
    );
}

PageGameUI.propTypes = {
    step: PropTypes.oneOf([STEP_INTO, STEP_NEW_GAME, STEP_CONNECT]),
    onPageClose: PropTypes.func,
    gameCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onNewGameStart: PropTypes.func.isRequired,
    onGameConnect: PropTypes.func.isRequired,
    onBackToMenu: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onFriendInvite: PropTypes.func,
    onCodeChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    isWaitingFriend: PropTypes.bool,
    friendGameUrl: PropTypes.string,
    onShareLink: PropTypes.func,
    hasUseLink: PropTypes.bool,
};

export default PageGameUI;
