import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogSeeProblemQuestionsUI from '../../../../components/Dialog/Types/SeeProblemQuestions';
import { hideDialog, showDialog } from '../../../../store/dialog';
import {
    getQuestionsWithProblems,
    sendQuestionProblem,
    sendDelete,
} from '../../../../store/questionProblem';
import { DIALOG_EDIT_QUESTION } from '../../../../containers/Dialog/Types/EditQuestion';

export default function DialogSeeProblemQuestions() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.profile.info);
    const isLoading = useSelector((state) => state.question.isLoading);
    const questions = useSelector((state) => state.questionProblem.questions);
    const isLastBlock = useSelector((state) => state.questionProblem.isLastBlock);
    const offset = questions.length;
    const isAdmin = user.type === 'admin';

    const handleCloseDialog = useCallback(() => {
        // Close
        dispatch(hideDialog());
    }, [dispatch]);

    const handleSaveMessageClick = useCallback(
        (questionId, message) => {
            dispatch(sendQuestionProblem(questionId, message));
        },
        [dispatch]
    );

    const handleDeleteClick = useCallback(
        (questionId) => {
            dispatch(sendDelete(questionId));
        },
        [dispatch]
    );

    const handleEditClick = useCallback(
        (questionId) => {
            dispatch(hideDialog());

            setTimeout(() => {
                dispatch(
                    showDialog(DIALOG_EDIT_QUESTION, {
                        questionId,
                    })
                );
            }, 200);
        },
        [dispatch]
    );

    const handleScroll = useCallback(
        (event) => {
            const totalHeight = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
            const currentPosition = event.currentTarget.scrollTop;

            if (!isLastBlock && totalHeight - currentPosition <= 200) {
                dispatch(getQuestionsWithProblems('append', offset));
            }
        },
        [dispatch, isLastBlock, offset]
    );

    useEffect(() => {
        dispatch(getQuestionsWithProblems('insert', 0));
    }, [dispatch]);

    const questionsData = useMemo(() => {
        return questions.map((entry) => ({
            question: entry.question,
            message: entry.message,
        }));
    }, [questions]);

    return (
        <DialogSeeProblemQuestionsUI
            questions={questionsData}
            onCancelClick={handleCloseDialog}
            isLoading={isLoading && offset === 0}
            isAppendLoading={isLoading && offset !== 0}
            onSaveMessageClick={handleSaveMessageClick}
            onDeleteClick={handleDeleteClick}
            onEditClick={isAdmin ? handleEditClick : undefined}
            onScroll={handleScroll}
        />
    );
}
