import { TEST_SERVER_API, PROD_SERVER_API } from '../index';

export const GROUP_VK_URL = 'https://vk.com/umnikclub';
export const GROUP_VK_ID = 78608125;
export const GROUP_OK_URL = '';

export const VK_APP_ID = 4533532;
export const VK_API_VERSION = '5.92';
export const VK_SITE_APP_ID = '5407486';
export const VK_TEST_REDIRECT = `${TEST_SERVER_API}/login-external/vk`;
export const VK_PROD_REDIRECT = `${PROD_SERVER_API}/login-external/vk`;

export const VK_GAME_URL = 'https://vk.com/umnikgame';
export const VK_PHOTO_SHARE_ID = 'photo-78608125_457239075';

export const OK_SITE_APP_ID = '1105498368';
export const OK_TEST_REDIRECT = `${TEST_SERVER_API}/login-external/ok`;
export const OK_PROD_REDIRECT = `${PROD_SERVER_API}/login-external/ok`;

export const FB_SITE_APP_ID = '189367233214617';
export const FB_TEST_REDIRECT = `${TEST_SERVER_API}/login-external/fb`;
export const FB_PROD_REDIRECT = `${PROD_SERVER_API}/login-external/fb`;
export const FB_SCOPE = 'public_profile';

export const GAME_SHARE_URL = 'https://umnikgame.ru';

export const getVKRedirectUrl = (isProduction) => {
    return isProduction ? VK_PROD_REDIRECT : VK_TEST_REDIRECT;
};

export const getOKRedirectUrl = (isProduction) => {
    return isProduction ? OK_PROD_REDIRECT : OK_TEST_REDIRECT;
};

export const getFBRedirectUrl = (isProduction) => {
    return isProduction ? FB_PROD_REDIRECT : FB_TEST_REDIRECT;
};
