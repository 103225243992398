import React from 'react';
import './style.scss';

import Logo from '../../images/logo.png';
import Loading from '../Loading';

export default function () {
    return (
        <div id="app-loading">
            <div className="app-loading-wrapper">
                <div className="logo">
                    <img src={Logo} alt={''} />
                </div>
                <div className="text">Идет загрузка игры...</div>
                <div className="app-loading">
                    <Loading />
                </div>
            </div>
        </div>
    );
}
