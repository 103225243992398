import React from 'react';
import PropTypes from 'prop-types';
import { HELPER_1_2, HELPER_1_4, HELPER_MAGIC, HELPER_TIME } from '../../../../../config/helpers';
import './style.scss';
import className from 'classnames';
import ReactTooltip from 'react-tooltip';
import { faMagic, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function HelperItemUI(props) {
    const renderValue = (id) => {
        switch (id) {
            case HELPER_1_4.id:
                return (
                    <div className={'text'}>
                        <span>¼</span>
                    </div>
                );
            case HELPER_1_2.id:
                return (
                    <div className={'text'}>
                        <span>½</span>
                    </div>
                );
            case HELPER_TIME.id:
                return (
                    <i className="icon">
                        <FontAwesomeIcon icon={faClock} />
                    </i>
                );
            case HELPER_MAGIC.id:
                return (
                    <i className="icon">
                        <FontAwesomeIcon icon={faMagic} />
                    </i>
                );
            default:
        }
    };

    const classes = className({
        'is-active': props.isActive,
        'is-disabled': !props.isActive,
        'helper-item': true,
    });

    const id = `${props.id}-tooltip`;

    return (
        <React.Fragment>
            <div
                className={classes}
                onClick={props.isActive ? props.onClick : undefined}
                data-tip
                data-for={id}
            >
                {renderValue(props.id)}
            </div>
            <ReactTooltip id={id} place="top" type="light" effect="solid">
                {props.title}
            </ReactTooltip>
        </React.Fragment>
    );
}

HelperItemUI.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};

export default HelperItemUI;
