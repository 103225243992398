import axios from 'axios';
import { SERVER_API } from '../../config';

export function updateQuestion({
    questionId,
    question,
    answer1,
    answer2,
    answer3,
    answer4,
    level,
    rightAnswer,
}) {
    return function (_dispatch, getState) {
        return axios.post(
            `${SERVER_API}/question`,
            {
                questionId,
                question,
                answer1,
                answer2,
                answer3,
                answer4,
                level,
                rightAnswer,
                session: getState().app.session,
            },
            { withCredentials: true }
        );
    };
}

export function deleteQuestion(questionId) {
    return async function (_dispatch, getState) {
        await axios.delete(`${SERVER_API}/question`, {
            params: { session: getState().app.session, questionId },
            withCredentials: true,
        });
    };
}

export function sendQuestionProblem(questionId, message = '') {
    return function (_dispatch, getState) {
        return axios.post(
            `${SERVER_API}/question/problem`,
            {
                questionId,
                message,
                session: getState().app.session,
            },
            { withCredentials: true }
        );
    };
}

export function sendDelete(questionId) {
    return async function (dispatch, getState) {
        await axios.delete(`${SERVER_API}/question/problem`, {
            params: { session: getState().app.session, questionId },
            withCredentials: true,
        });

        dispatch(removeQuestion(questionId));
    };
}

export function getQuestion(questionId) {
    return function (dispatch, getState) {
        return axios
            .get(`${SERVER_API}/question`, {
                params: { session: getState().app.session, questionId },
                withCredentials: true,
            })
            .then((response) => {
                dispatch(setWorkingQuestion(response.data.question));
                dispatch(setProblems(response.data.problems));
            });
    };
}

let questionWithProblemLoading = false;

export function getQuestionsWithProblems(type = 'insert', offset = 0) {
    return function (dispatch, getState) {
        if (questionWithProblemLoading) {
            return;
        }

        questionWithProblemLoading = true;
        dispatch(setIsLoading(true));

        if (offset === 0) {
            dispatch(setQuestions([]));
        }

        return axios
            .get(
                `${SERVER_API}/question/problem?session=${getState().app.session}&offset=${offset}`,
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (type === 'append') {
                    dispatch(appendQuestions(response.data.questions));
                } else {
                    dispatch(setQuestions(response.data.questions));
                }

                dispatch(setIsLoading(false));
                questionWithProblemLoading = false;
            });
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');
function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

const SET_WORKING_QUESTION = Symbol('SET_WORKING_QUESTION');
export function setWorkingQuestion(workingQuestion = false) {
    return {
        type: SET_WORKING_QUESTION,
        workingQuestion,
    };
}

const SET_QUESTIONS = Symbol('SET_QUESTIONS');
function setQuestions(questions = []) {
    return {
        type: SET_QUESTIONS,
        questions,
    };
}

const APPEND_QUESTIONS = Symbol('APPEND_QUESTIONS');
function appendQuestions(questions = []) {
    return {
        type: APPEND_QUESTIONS,
        questions,
    };
}

const REMOVE_QUESTION = Symbol('REMOVE_QUESTION');
function removeQuestion(questionId) {
    return {
        type: REMOVE_QUESTION,
        questionId,
    };
}

const SET_WORKING_QUESTION_PROBLEMS = Symbol('SET_WORKING_QUESTION_PROBLEMS');
function setProblems(workingQuestionProblems = []) {
    return {
        type: SET_WORKING_QUESTION_PROBLEMS,
        workingQuestionProblems,
    };
}

const initialState = {
    workingQuestion: null,
    workingQuestionProblems: [],
    isLoading: false,
    questions: [],
    isLastBlock: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    exec[SET_WORKING_QUESTION] = function () {
        return {
            ...state,
            workingQuestion: action.workingQuestion,
        };
    };

    exec[SET_QUESTIONS] = function () {
        return {
            ...state,
            questions: action.questions,
        };
    };

    exec[SET_WORKING_QUESTION_PROBLEMS] = function () {
        return {
            ...state,
            workingQuestionProblems: action.workingQuestionProblems,
        };
    };

    exec[APPEND_QUESTIONS] = function () {
        return {
            ...state,
            questions: [...state.questions, ...action.questions],
            isLastBlock: action.questions.length === 0,
        };
    };

    exec[REMOVE_QUESTION] = function () {
        const questions = state.questions.filter(
            (entry) => entry.question.id !== action.questionId
        );

        return {
            ...state,
            questions,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
