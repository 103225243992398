import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PageCross = function (props) {
    return (
        <div className="page-cross" onClick={props.onClick}>
            <FontAwesomeIcon icon={faTimes} />
        </div>
    );
};

PageCross.propTypes = {
    onClick: PropTypes.func,
};

export default PageCross;
