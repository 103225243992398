import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import RatingUserModel from '../../../../model/ratingUser';
import UserInfoUI from '../../../UI/UserInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faTrophy, faStar, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { numberWithSpaces } from '../../../../utils';
import i18n from '../../../../i18n';

function RatingUserUI(props) {
    /**
     *
     * @type {RatingUserModel}
     */
    const ratingUser = props.user;

    return (
        <div className="rating-user hover-item" onClick={props.onClick}>
            <div className="rating-position">
                <span>{ratingUser.position}</span>
            </div>

            <UserInfoUI
                photoUrl={ratingUser.user.photoUrl}
                level={ratingUser.user.level}
                type={UserInfoUI.TYPE.MEDIUM}
            />

            <div className="user">
                <div className="name">
                    {ratingUser.user.firstName} {ratingUser.user.lastName}
                </div>
                <div className="games-info">
                    {ratingUser.questionsCount ? (
                        <>
                            <div className="info-item score">
                                <i className="icon">
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </i>
                                <span>
                                    {i18n.t('pages.rating.questions', {
                                        count: ratingUser.questionsCount,
                                    })}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="info-item score">
                                <i className="icon">
                                    <FontAwesomeIcon icon={faStar} />
                                </i>
                                <span>{numberWithSpaces(ratingUser.points)}</span>
                            </div>
                            <div className="info-item games-win">
                                <i className="icon">
                                    <FontAwesomeIcon icon={faTrophy} />
                                </i>
                                <span>{numberWithSpaces(ratingUser.gamesWin)}</span>
                            </div>
                            <div className="info-item games-played">
                                <i className="icon">
                                    <FontAwesomeIcon icon={faGamepad} />
                                </i>
                                <span>{numberWithSpaces(ratingUser.gamesCount)}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

RatingUserUI.propTypes = {
    user: PropTypes.instanceOf(RatingUserModel),
    onClick: PropTypes.func,
};

export default RatingUserUI;
