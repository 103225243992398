import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import UserModel from '../../../model/user';
import PageCrossUI from '../../UI/PageCross';
import LoadingUI from '../../Loading';
import LevelProgressUI from '../../UI/LevelProgress';
import ProgressItemUI from '../../UI/ProgressItem';
import UserInfoUI from '../../UI/UserInfo';
import GameResultUI from './GameResult';
import NoGamesUI from './NoGames';
import GameResultModel from '../../../model/gameResult';
import ButtonUI from '../../UI/Button';
import { numberWithSpaces } from '../../../utils';
import i18n from '../../../i18n';

import IconCoins from '../../../images/icons/coins-bw.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGamepad,
    faTrophy,
    faBug,
    faUserPlus,
    faMapMarkerAlt,
    faQuestion,
    faExclamationCircle,
    faPlusCircle,
    faPen,
} from '@fortawesome/free-solid-svg-icons';

const renderGames = (entries) => {
    return entries.map((entry) => (
        <GameResultUI
            key={entry.key}
            game={entry.game}
            onFirstPlayerClick={entry.onFirstPlayerClick}
            onSecondPlayerClick={entry.onSecondPlayerClick}
        />
    ));
};

const renderProfileButtons = (props) => {
    return (
        <div className={'profile-buttons'}>
            {props.onInviteFriends ? (
                <ButtonUI
                    text={'Пригласить друзей'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline invite"
                    iconElement={<FontAwesomeIcon icon={faUserPlus} />}
                    onClick={props.onInviteFriends}
                />
            ) : null}
            {props.onBuyCoins ? (
                <ButtonUI
                    text={'Добавить монет'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline coins"
                    icon={IconCoins}
                    onClick={props.onBuyCoins}
                />
            ) : null}
            {props.onAddQuestion ? (
                <ButtonUI
                    text={'Добавить вопрос'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline add-question"
                    iconElement={<FontAwesomeIcon icon={faPlusCircle} />}
                    onClick={props.onAddQuestion}
                />
            ) : null}
            {props.onShowMyQuestions ? (
                <ButtonUI
                    text={'Мои вопросы'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline add-question"
                    iconElement={<FontAwesomeIcon icon={faQuestion} />}
                    onClick={props.onShowMyQuestions}
                />
            ) : null}
            {props.onShowProblemQuestions ? (
                <ButtonUI
                    text={'Ошибки в вопросах'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline add-question"
                    iconElement={<FontAwesomeIcon icon={faExclamationCircle} />}
                    onClick={props.onShowProblemQuestions}
                />
            ) : null}
            {props.onEditProfile ? (
                <ButtonUI
                    text={'Редактировать профиль'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline add-question"
                    iconElement={<FontAwesomeIcon icon={faPen} />}
                    onClick={props.onEditProfile}
                />
            ) : null}
        </div>
    );
};

const renderUser = (props) => {
    const { user, lastGames, isOwnProfile } = props;
    const hasActions = props.onInviteFriends || props.onBuyCoins || props.onGroupJoin;

    return (
        <div className="user-container">
            <div className="user-progress-block">
                <div className="photo-wrapper">
                    <UserInfoUI
                        photoUrl={user.photoUrl}
                        level={user.level}
                        hasBoost={user.hasBoost}
                        hasBonuses={user.hasBonuses}
                        bonuses={user.bonuses}
                    />
                </div>
                {user.city ? (
                    <div className="location">
                        <i className="icon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </i>
                        <span>{user.city}</span>
                    </div>
                ) : null}
                <div className="user-info-block">
                    <div className="progress-block">
                        <LevelProgressUI
                            levelNeedPoints={user.levelNeedPoints}
                            levelPoints={user.levelPoints}
                            totalPoints={user.totalPoints}
                        />
                        <div className="info">
                            <ProgressItemUI
                                key={'games-count'}
                                icon={faGamepad}
                                value={numberWithSpaces(user.gamesCount)}
                                text={i18n.t('games')}
                            />
                            <ProgressItemUI
                                key={'games-win'}
                                icon={faTrophy}
                                value={numberWithSpaces(user.gamesWinCount)}
                                text={i18n.t('winGames')}
                            />
                            <ProgressItemUI
                                key={'coins'}
                                icon={faBug}
                                value={user.leavesСount}
                                text={'Нарушений'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isOwnProfile && hasActions ? renderProfileButtons(props) : null}

            <div className="last-games">
                <h2>Недавние игры</h2>
                {lastGames.length === 0 ? <NoGamesUI /> : renderGames(lastGames)}
            </div>
        </div>
    );
};

function PageProfileUI(props) {
    return (
        <div id="page-profile" className="page with-background">
            <PageCrossUI onClick={props.onPageClose} />
            <h1>{props.pageTitle}</h1>
            {props.isLoading ? <LoadingUI loadingText={props.loadingText} /> : renderUser(props)}
        </div>
    );
}

PageProfileUI.propTypes = {
    onPageClose: PropTypes.func.isRequired,
    onInviteFriends: PropTypes.func,
    onBuyCoins: PropTypes.func,
    onGroupJoin: PropTypes.func,
    onShowMyQuestions: PropTypes.func,
    onShowProblemQuestions: PropTypes.func,
    onEditProfile: PropTypes.func,
    user: PropTypes.instanceOf(UserModel),
    isOwnProfile: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    pageTitle: PropTypes.string,
    lastGames: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            game: PropTypes.instanceOf(GameResultModel).isRequired,
            onFirstPlayerClick: PropTypes.func.isRequired,
            onSecondPlayerClick: PropTypes.func.isRequired,
        })
    ),
};

export default PageProfileUI;
