import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/App';
import './reset.scss';
import './style.scss'; // Main styles
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import { configureStore } from './configureStore';
import vkBridge from '@vkontakte/vk-bridge';
import { createBrowserHistory } from 'history';
import { setVkBridge } from './utils/vkBridge';
import { IS_PRODUCTION } from './config';
import { getParameterByName } from './utils';

const KEY_APP_TYPE = 'appType';
const VK_PLATFROM_IOS = 'html5_ios';
const VK_PLATFROM_ANDROID = 'html5_android';
const VK_PLATFORM_KEY = 'platform';

const history = createBrowserHistory();
const store = configureStore(history);

setVkBridge(vkBridge);

moment.locale('ru');

let appType = getParameterByName(KEY_APP_TYPE);
const vkPlatform = getParameterByName(VK_PLATFORM_KEY);

if (!appType && !IS_PRODUCTION) {
    appType = window.sessionStorage.getItem(KEY_APP_TYPE);
}

if (window.location.host.match('yandex')) {
    appType = 'yandex';
}

ReactDOM.render(
    <Provider store={store}>
        <App
            isVkIos={vkPlatform === VK_PLATFROM_IOS}
            isVkAndroid={vkPlatform === VK_PLATFROM_ANDROID}
            queryAppType={appType}
            openUrl={window.location.href}
        />
    </Provider>,
    document.getElementById('root')
);

vkBridge.send('VKWebAppInit', {});
