class GameHelperModel {
    id = null;
    title = null;
    isActive = null;
    price = null;

    constructor(userData) {
        this.id = userData.key;
        this.title = userData.title;
        this.isActive = userData.active;
        this.price = userData.price;
    }
}

export default GameHelperModel;
