import UserModel from './user';

class RatingUserModel {
    position = null;
    points = 0;
    gamesWin = 0;
    gamesCount = 0;
    user = null;
    questionsCount = 0;

    constructor(userData) {
        this.position = userData.rating_position;
        this.points = userData.rating_points;
        this.gamesWin = userData.rating_games_win;
        this.gamesCount = userData.rating_games_count;
        this.questionsCount = userData.questions_count;

        this.user = new UserModel(userData.user);
    }
}

export default RatingUserModel;
