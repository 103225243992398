class RoundModel {
    constructor(data) {
        this.id = data.id;
        this.question = data.question;
        this.questionId = data.questionId;
        this.answers = data.answers;

        if (data.author && data.author.firstName) {
            this.author = {
                firstName: data.author.firstName,
                lastName: data.author.lastName,
            };
        }
    }
}

export default RoundModel;
