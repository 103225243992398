import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogEditQuestionUI from '../../../../components/Dialog/Types/EditQuestion';
import { hideDialog, showDialog } from '../../../../store/dialog';
import { getQuestion, updateQuestion, deleteQuestion } from '../../../../store/questionProblem';
import { DIALOG_SEE_PROBLEM_QUESTIONS } from '../../Types/SeeProblemQuestions/type';

export const DIALOG_EDIT_QUESTION = 'edit-question';

export default function DialogEditQuestion({ questionId }) {
    const dispatch = useDispatch();
    const workingQuestion = useSelector((state) => state.questionProblem.workingQuestion);
    const problems = useSelector((state) => state.questionProblem.workingQuestionProblems);

    const [isLoading, setIsLoading] = useState(false);

    const handleContinueClick = useCallback(
        ({ question, answer1, answer2, answer3, answer4, level, rightAnswer }) => {
            setIsLoading(true);
            dispatch(
                updateQuestion({
                    questionId,
                    question,
                    answer1,
                    answer2,
                    answer3,
                    answer4,
                    level,
                    rightAnswer,
                })
            )
                .then(() => {
                    dispatch(hideDialog());
                    setTimeout(() => {
                        dispatch(showDialog(DIALOG_SEE_PROBLEM_QUESTIONS));
                    }, 200);
                })
                .catch((result) => {
                    setIsLoading(false);
                    const message = (result && result.error) || 'Ошибка сохранения вопроса';
                    // eslint-disable-next-line no-alert
                    alert(message);
                });
        },
        [dispatch, questionId]
    );

    const handleDeleteClick = useCallback(async () => {
        setIsLoading(true);
        await dispatch(deleteQuestion(questionId));
        dispatch(hideDialog());

        setTimeout(() => {
            dispatch(showDialog(DIALOG_SEE_PROBLEM_QUESTIONS));
        }, 200);
    }, [dispatch, questionId]);

    const handleCancelClick = useCallback(() => {
        dispatch(hideDialog());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(true);

        dispatch(getQuestion(questionId)).then(() => {
            setIsLoading(false);
        });
    }, [questionId, dispatch]);

    return (
        <DialogEditQuestionUI
            onContinueClick={handleContinueClick}
            onCancelClick={handleCancelClick}
            onDeleteClick={handleDeleteClick}
            isLoading={isLoading}
            workingQuestion={workingQuestion}
            problems={problems}
        />
    );
}
