export default {
    gameName: 'Umnik!',
    greeting: 'Hello {{userName}}!',
    appLoading: 'Loading application',
    exitFromApp: 'Exit',
    exitFromAppDescription: 'Do you want to exit from this account?',
    exit: 'Yes',
    cancel: 'Cancel',
    submit: 'Submit',
    signUp: 'Sign Up',
    tabs: {
        game: 'Game',
        rating: 'Rating',
        shop: 'Bonuses',
        profile: 'Profile',
    },
    answersLetters: {
        1: 'A',
        2: 'B',
        3: 'C',
        4: 'D',
    },
    inviteSubject: 'Приглашаю тебя в игру Умник!',
    inviteBody: `Привет! Я приглашаю тебя сыграть со мной в интеллектуальную викторину Умник! Скорее заходи на https://umnikgame.ru/`,
    inviteBodyShort: `Привет! Я приглашаю тебя сыграть со мной в интеллектуальную викторину Умник!`,
    inviteWallPost:
        'Я играю в интеллектуальную викторину Умник! Присоединяйтесь и сыграйте со мной.',
    userRequestVk:
        'Привет, {{name}}! Хочу сыграть с тобой в викторине Умник! Скорее заходи в игру {{url}}',
    pages: {
        home: {
            helloText: {
                one: 'Many users are waiting you in game',
                few: 'There are {{count}} users is waiting you in game',
                many: 'There are {{count}} users is waiting you in game',
                other: 'There are {{count}} users is waiting you in game',
            },
        },
        profile: {
            title: 'Your profile',
            lastGames: 'Last games',
            noGamesYet: 'No games yet',
            rounds: {
                one: '{{count}} round',
                few: '{{count}} rounds',
                many: '{{count}} rounds',
                other: '{{count}} rounds',
            },
            points: {
                one: '{{count}} point',
                few: '{{count}} points',
                many: '{{count}} points',
                other: '{{count}} points',
            },
        },
        rating: {
            title: 'Players rating',
            loadUsersList: 'Loading users',
            questions: {
                one: 'Added {{count}} question',
                few: 'Added {{count}} questions',
                many: 'Added {{count}} questions',
                other: 'Added {{count}} questions',
            },
        },
        shop: {
            title: 'Bonus shop',
            itemPrice: {
                one: 'Price: {{count}} coin',
                few: 'Price: {{count}} coins',
                many: 'Price: {{count}} coins',
                other: 'Price: {{count}} coins',
            },
            loadingText: 'Loading bonuses',
        },
        user: {
            title: 'Player profile',
        },
        game: {
            youWon: 'You are winner!',
            youLose: 'You lose the game',
            draw: 'It is draw!',
            timeIsOut: 'Time is out',
            endGame: 'End game',
        },
        join: {
            title: 'Join game',
            yourEmailAddress: 'Email address',
            yourPassword: 'Password',
            enterPassword: 'Enter password',
            credentials: 'Your details',
            firstName: 'First name',
            lastName: 'Last name',
            welcome: 'Welcome',
            welcomeDescription: 'To complete registration please fill \n few things about you',
            enterFirstName: 'Enter first name',
            enterLastName: 'Enter last name',
            uploadActionText: 'Press to upload your photo',
        },
    },
    points: {
        one: '{{count}} point',
        few: '{{count}} points',
        many: '{{count}} points',
        other: '{{count}} points',
    },
    coinsCount: {
        one: '{{count}} coin',
        few: '{{count}} coins',
        many: '{{count}} coins',
        other: '{{count}} coins',
    },
    day: 'Day',
    week: 'Week',
    month: 'Month',
    total: 'Total',
    authors: 'Authors',
    totalPoints: 'Total points: {{points}}',
    totalPointsWithoutNumber: 'total points',
    games: 'games',
    winGames: 'win games',
    moves: {
        one: '{{count}} move',
        few: '{{count}} moves',
        many: '{{count}} moves',
        other: '{{count}} moves',
    },
    openCards: {
        one: '{{count}} correct',
        few: '{{count}} correct',
        many: '{{count}} correct',
        other: '{{count}} correct',
    },
    authorization: 'Log in',
    todayTop: 'Today top users',
    helloUser: 'Hello {{userName}}',
    vkontakte: 'Vkontakte',
    odnoklassniki: 'Odnoklassniki',
    facebook: 'Facebook',
    authHelperText: 'Please log in to use the app',
    singleGame: 'Single game',
    randomGame: 'Multiplayer',
    coins: 'Coins',
    level: 'Level',
    error: 'Error',
    loadingGame: 'Game is loading',
    opponentSearch: 'Looking for rival',
    waitingSecondPlayer: 'waiting second player',
    cancelIt: 'Cancel',
    roundValue: 'Round {{value}}',
    rightAnswersCount: 'Correct',
    answersCount: 'Moves',
    pointsValue: 'Points',
    yourHelpers: 'Your tips',
    helperActivated: 'Your tip is activated',
    memoton: 'Memoton',
    noActiveGames: 'It looks like we dont have active users at this moment.',
    opponentMove: 'Rival move',
    opponentMoveHint: 'please wait',
    userDisconnected: 'User disconnected',
    newGame: 'New game',
    earnedPoints: 'Earned points',
    noInternetConnection: 'No internet connection',
    noInternetConnectionText:
        ' The game can’t be continued without internet connection. Please, check your network.',
    levelProgress: 'Level progress',
    noServerConnection: 'No connection to game server',
    noServerConnectionText:
        'Our server temporarily unavailable. \n We are working on solving this problem.',
    join: 'Join game',
    or: 'or',
    weNeedImage: 'Please select your photo',
    imagePermissionError: 'Sorry, we need camera roll permissions upload your photo',
    privacyPolicy: 'Privacy policy',
    winUserTitle: 'Winner',
    youWinTitle: 'You are winner!',
};
