import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogSeeOwnQuestionsUI from '../../../../components/Dialog/Types/SeeOwnQuestions';
import { hideDialog, showDialog } from '../../../../store/dialog';
import { getUserQuestions, setWorkingQuestion } from '../../../../store/question';
import { DIALOG_MANAGE_QUESTION } from '../../../../containers/Dialog/Types/ManageQuestion';

export default function DialogSeeOwnQuestions() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.question.isLoading);
    const questions = useSelector((state) => state.question.questions);
    const isLastBlock = useSelector((state) => state.question.isLastBlock);
    const offset = questions.length;

    const [activeMode, setActiveMode] = useState('new');

    const handleCloseDialog = useCallback(() => {
        // Close
        dispatch(hideDialog());
    }, [dispatch]);

    const handleChangeClick = useCallback(
        (question) => {
            dispatch(hideDialog());
            dispatch(setWorkingQuestion(question));
            setTimeout(() => {
                dispatch(showDialog(DIALOG_MANAGE_QUESTION));
            }, 100);
        },
        [dispatch]
    );

    const handleScroll = useCallback(
        (event) => {
            const totalHeight = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
            const currentPosition = event.currentTarget.scrollTop;

            if (!isLastBlock && totalHeight - currentPosition <= 200) {
                dispatch(getUserQuestions('append', offset, activeMode));
            }
        },
        [dispatch, isLastBlock, offset, activeMode]
    );

    const handleMenuClick = useCallback(
        (mode) => {
            setActiveMode(mode);
            dispatch(getUserQuestions('insert', 0, mode));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(getUserQuestions('insert', 0, activeMode));
    }, [dispatch, activeMode]);

    return (
        <DialogSeeOwnQuestionsUI
            questions={questions}
            onCancelClick={handleCloseDialog}
            isLoading={isLoading && offset === 0}
            isAppendLoading={isLoading && offset !== 0}
            onChangeClick={handleChangeClick}
            onScroll={handleScroll}
            menu={[
                {
                    label: 'Новые',
                    onClick: handleMenuClick.bind(null, 'new'),
                    isActive: activeMode === 'new',
                },
                {
                    label: 'Старые',
                    onClick: handleMenuClick.bind(null, 'old'),
                    isActive: activeMode === 'old',
                },
                {
                    label: 'В игре',
                    onClick: handleMenuClick.bind(null, 'in-game'),
                    isActive: activeMode === 'in-game',
                },
                {
                    label: 'Не проверены',
                    onClick: handleMenuClick.bind(null, 'pending'),
                    isActive: activeMode === 'pending',
                },
                {
                    label: 'Последние исправленные',
                    onClick: handleMenuClick.bind(null, 'last-modified'),
                    isActive: activeMode === 'last-modified',
                },
            ]}
        />
    );
}
