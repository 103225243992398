import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageProfileUI from '../../../components/Pages/Profile';
import { URL_HOME, URL_MONEY, URL_PROFILE } from '../../../config/url';
import { goToPage, showInvite, TYPE_FB, TYPE_VK, TYPE_VK_APP, TYPE_WS } from '../../../store/app';
import { GROUP_VK_URL } from '../../../config/social';
import { fetchLastProfileGames, fetchProfile } from '../../../store/profile';
import { DIALOG_ADD_QUESTION } from '../../Dialog/Types/AddQuestion';
import { DIALOG_SEE_OWN_QUESTIONS } from '../../Dialog/Types/SeeOwnQuestions/type';
import { DIALOG_SEE_PROBLEM_QUESTIONS } from '../../Dialog/Types/SeeProblemQuestions/type';
import { DIALOG_UPDATE_USER_DETAILS } from '../../Dialog/Types/UpdateUserDetails';
import { showDialog } from '../../../store/dialog';

const MINIMUM_LEVEL_TO_ADD_QUESTION = 20;

class PageProfile extends PureComponent {
    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);
        this.handleInviteFriends = this.handleInviteFriends.bind(this);
        this.handleBuyCoins = this.handleBuyCoins.bind(this);
        this.handleClubJoin = this.handleClubJoin.bind(this);
        this.handleAddQuestion = this.handleAddQuestion.bind(this);
        this.handleSeeOwnQuestions = this.handleSeeOwnQuestions.bind(this);
        this.handleSeeQuestionsWithProblems = this.handleSeeQuestionsWithProblems.bind(this);
        this.handleEditProfile = this.handleEditProfile.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchProfile();
        this.props.actions.fetchLastProfileGames();
    }

    render() {
        const canSendInvite =
            [TYPE_VK, TYPE_VK_APP, TYPE_FB, TYPE_WS].indexOf(this.props.appType) !== -1;

        const canBuyCoins = this.props.isBuyMoneyAllowed || this.props.isAllowSeeAdv;
        const canManageQuestions =
            this.props.ownProfile &&
            (this.props.ownProfile.level >= MINIMUM_LEVEL_TO_ADD_QUESTION ||
                this.props.ownProfile.type === 'admin');
        const canSeeProblemQuestions =
            this.props.ownProfile &&
            (this.props.ownProfile.isAllowModerate || this.props.ownProfile.type === 'admin');
        const canEditProfile = this.props.ownProfile.isNeedUpdateProfile === 'Y';

        return (
            <PageProfileUI
                onPageClose={this.handlePageClose}
                onInviteFriends={canSendInvite ? this.handleInviteFriends : undefined}
                onBuyCoins={canBuyCoins ? this.handleBuyCoins : undefined}
                isOwnProfile={true}
                pageTitle={'Ваш профиль'}
                user={this.props.ownProfile}
                loadingText={'Загрузка профиля'}
                lastGames={this.getLastGames()}
                onAddQuestion={canManageQuestions ? this.handleAddQuestion : undefined}
                onShowMyQuestions={canManageQuestions ? this.handleSeeOwnQuestions : undefined}
                onShowProblemQuestions={
                    canSeeProblemQuestions ? this.handleSeeQuestionsWithProblems : undefined
                }
                onEditProfile={canEditProfile ? this.handleEditProfile : undefined}
            />
        );
    }

    handlePageClose() {
        this.props.actions.goToPage(URL_HOME);
    }

    getLastGames() {
        if (!this.props.lastGames) {
            return [];
        }

        return this.props.lastGames.map((game) /** @param game GameResultModel */ => {
            return {
                key: game.id,
                game,
                onFirstPlayerClick: this.handleOpenUser.bind(this, game.firstPlayer),
                onSecondPlayerClick: this.handleOpenUser.bind(this, game.secondPlayer),
            };
        });
    }

    handleOpenUser(gameUser) {
        this.props.actions.goToPage(`${URL_PROFILE}/${gameUser.id}`, {
            user: gameUser,
            hasBack: true,
        });
    }

    handleInviteFriends() {
        this.props.actions.showInvite(this.props.appType);
    }

    handleBuyCoins() {
        this.props.actions.goToPage(URL_MONEY);
    }

    handleClubJoin() {
        window.open(GROUP_VK_URL, '_blank');
    }

    handleAddQuestion() {
        this.props.actions.showDialog(DIALOG_ADD_QUESTION);
    }

    handleSeeOwnQuestions() {
        this.props.actions.showDialog(DIALOG_SEE_OWN_QUESTIONS);
    }

    handleSeeQuestionsWithProblems() {
        this.props.actions.showDialog(DIALOG_SEE_PROBLEM_QUESTIONS);
    }

    handleEditProfile() {
        this.props.actions.showDialog(DIALOG_UPDATE_USER_DETAILS, { isRequired: false });
    }
}

const mapStateToProps = function (state) {
    return {
        ownProfile: state.profile.info,
        lastGames: state.profile.lastGames,
        appType: state.app.appType,
        isBuyMoneyAllowed: state.app.isBuyMoneyAllowed,
        isAllowSeeAdv: state.app.isAllowSeeAdv,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                showInvite,
                fetchLastProfileGames,
                fetchProfile,
                showDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageProfile);
