import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import PageMoneyUI from '../../../components/Pages/Money';
import MoneyItemUI from '../../../components/Pages/Money/Item';

import { URL_HOME } from '../../../config/url';
import { ADV_REWARD } from '../../../config';
import { goToPage, showOrderBox, showAdv, TYPE_YANDEX } from '../../../store/app';
import { fetchMoneyItems } from '../../../store/money';
import { sendReachGoal } from '../../../store/statistic';
import { sendSawAdvForMoney } from '../../../store/profile';
import { showDialog } from '../../../store/dialog';
import { DIALOG_ALERT } from '../../Dialog/Types/Alert';

import RewardIcon from '../../../images/icons/reward.svg';

let yandexPayments = null;

class PageMoney extends PureComponent {
    constructor(props) {
        super(props);

        this.handleOnItemClick = this.handleOnItemClick.bind(this);
        this.handlePageClose = this.handlePageClose.bind(this);
        this.handleOnSeeAdvClick = this.handleOnSeeAdvClick.bind(this);
    }

    componentDidMount() {
        if (this.props.moneyItems.length === 0) {
            this.props.actions.fetchMoneyItems(this.props.appType);
        }

        if (this.props.appType === TYPE_YANDEX && !yandexPayments) {
            window.YaGames.init().then((ysdk) => {
                ysdk.getPayments({ signed: true })
                    .then((_payments) => {
                        yandexPayments = _payments;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    }

    render() {
        return (
            <PageMoneyUI
                isLoading={this.props.isLoading}
                loadingText={'Загрузка элементов'}
                moneyElements={this.getItems()}
                onPageClose={this.handlePageClose}
            />
        );
    }

    getItems() {
        let config = [];

        if (this.props.isBuyMoneyAllowed) {
            config = this.props.moneyItems.map(
                /** MoneyModel */ (item) => {
                    return (
                        <MoneyItemUI
                            key={item.type}
                            title={item.title}
                            price={`Цена: ${item.price}`}
                            oldPrice={item.oldPrice}
                            helperText={item.helperText}
                            type={item.type}
                            iconUrl={item.image}
                            onClick={this.handleOnItemClick.bind(this, item)}
                        />
                    );
                }
            );
        }

        if (this.props.isAllowSeeAdv) {
            config.unshift(
                <MoneyItemUI
                    key={'adv'}
                    title={'Монеты за рекламу'}
                    price={'Нажми и получи 1 монету за каждый просмотр'}
                    type={'adv'}
                    iconUrl={RewardIcon}
                    onClick={this.handleOnSeeAdvClick}
                />
            );
        }

        return config;
    }

    /**
     *
     * @param {MoneyModel} item
     */
    handleOnItemClick(item) {
        this.props.actions.showOrderBox(this.props.appType, item, { yandexPayments });
    }

    handlePageClose() {
        this.props.actions.goToPage(URL_HOME);
    }

    handleOnSeeAdvClick() {
        const { user } = this.props;

        if (user.advBlockingDate && moment.utc(user.advBlockingDate) > moment()) {
            // eslint-disable-next-line no-alert
            this.props.actions.showDialog(DIALOG_ALERT, {
                title: 'Ошибка',
                message: 'Исчерпан лимит на показы сегодня',
            });
        } else {
            this.props.actions
                .showAdv(ADV_REWARD, user.externalId)
                .then(() => {
                    this.props.actions.sendSawAdvForMoney().catch(() => {});
                })
                .catch(() => {
                    this.props.actions.showDialog(DIALOG_ALERT, {
                        title: 'Реклама не найдена',
                        message:
                            'К сожалению в данный момент реклама отсутствует. Попробуйте позже',
                    });
                });
        }
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
        moneyItems: state.money.items,
        isLoading: state.money.isLoading,
        isAllowSeeAdv: state.app.isAllowSeeAdv,
        isBuyMoneyAllowed: state.app.isBuyMoneyAllowed,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                showOrderBox,
                fetchMoneyItems,
                sendReachGoal,
                sendSawAdvForMoney,
                showAdv,
                showDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageMoney);
