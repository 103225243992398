import React from 'react';
import './style.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TYPE_VK_APP, TYPE_WS } from '../../store/app';

function AppUI(props) {
    const pageClasses = classNames({
        'app-vk-app': props.appType === TYPE_VK_APP,
        'app-ws': props.appType === TYPE_WS,
    });

    return (
        <div id="app" className={pageClasses}>
            <div className="app-bg"></div>
            {props.children}
        </div>
    );
}

AppUI.propTypes = {
    appType: PropTypes.string,
};

export default AppUI;
