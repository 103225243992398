import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import DialogUI from '../../components/Dialog';

import DialogUserDisconnected, { DIALOG_USER_DISCONNECTED } from './Types/UserDisconnected';
import DialogSingleGameFinish, { DIALOG_SINGLE_GAME_FINISH } from './Types/SingleGameFinish';
import DialogMultiplayerGameFinish, {
    DIALOG_MULTIPLAYER_GAME_FINISH,
} from './Types/MultiplayerGameFinish';
import DialogAddQuestion, { DIALOG_ADD_QUESTION } from './Types/AddQuestion';
import DialogManageQuestion, { DIALOG_MANAGE_QUESTION } from './Types/ManageQuestion';
import DialogSeeOwnQuestions from './Types/SeeOwnQuestions';
import { DIALOG_SEE_OWN_QUESTIONS } from './Types/SeeOwnQuestions/type';
import DialogAlert, { DIALOG_ALERT } from './Types/Alert';
import DialogSeeProblemQuestions from './Types/SeeProblemQuestions';
import { DIALOG_SEE_PROBLEM_QUESTIONS } from './Types/SeeProblemQuestions/type';
import DialogEditQuestion, { DIALOG_EDIT_QUESTION } from './Types/EditQuestion';
import DialogUpdateUserDetails, { DIALOG_UPDATE_USER_DETAILS } from './Types/UpdateUserDetails';

const MAP = {
    [DIALOG_USER_DISCONNECTED]: DialogUserDisconnected,
    [DIALOG_SINGLE_GAME_FINISH]: DialogSingleGameFinish,
    [DIALOG_MULTIPLAYER_GAME_FINISH]: DialogMultiplayerGameFinish,
    [DIALOG_ADD_QUESTION]: DialogAddQuestion,
    [DIALOG_MANAGE_QUESTION]: DialogManageQuestion,
    [DIALOG_SEE_OWN_QUESTIONS]: DialogSeeOwnQuestions,
    [DIALOG_ALERT]: DialogAlert,
    [DIALOG_SEE_PROBLEM_QUESTIONS]: DialogSeeProblemQuestions,
    [DIALOG_EDIT_QUESTION]: DialogEditQuestion,
    [DIALOG_UPDATE_USER_DETAILS]: DialogUpdateUserDetails,
};

class Dialog extends PureComponent {
    render() {
        let dialog;

        if (this.props.activeDialogType && MAP[this.props.activeDialogType]) {
            const ActiveDialog = MAP[this.props.activeDialogType];

            dialog = React.createElement(ActiveDialog, this.props.openParams, null);
        }

        return dialog ? <DialogUI type={this.props.activeDialogType}>{dialog}</DialogUI> : null;
    }
}

const mapStateToProps = function (state) {
    return {
        activeDialogType: state.dialog.activeDialogType,
        openParams: state.dialog.openParams,
    };
};

const mapDispatchToProps = function () {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
