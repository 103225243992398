import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

import PlayUser from '../../../../model/playUser';

import UserInfoUI from '../../../UI/UserInfo';
import Button from '../../../UI/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faSmile } from '@fortawesome/free-solid-svg-icons';

import i18n from '../../../../i18n';

function MultiplayerGameFinishUI(props) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-round-finished': true,
        'dialog-multiplayer': true,
        'is-winner': props.isWinner,
    });

    return (
        <div className={classes}>
            <h2>{i18n.t('winUserTitle')}</h2>

            <div className={'game-winner'}>
                {props.user ? (
                    <div className="winner-wrapper">
                        <UserInfoUI photoUrl={props.user.photoUrl} type={UserInfoUI.TYPE.BIG} />
                        <div className="name">{props.user.firstName}</div>
                    </div>
                ) : (
                    <div className="winner-wrapper">
                        <div className="draw">
                            <i className="icon">
                                <FontAwesomeIcon icon={faSmile} mask={['far', 'circle']} />
                            </i>
                        </div>
                        <div className="name">Победила дружба!</div>
                    </div>
                )}
            </div>

            {props.points ? (
                <div className={'game-info'}>
                    <div className="game-info-title">Ваша награда</div>
                    <div className="stat-block points">
                        <i className="icon">
                            <FontAwesomeIcon icon={faStar} />
                        </i>
                        <span>{props.points} очков</span>
                    </div>
                </div>
            ) : null}

            <div className={'buttons'}>
                <Button text={'Новая игра'} onClick={props.onContinueClick} />
                <div className="sub-button" onClick={props.onCancelClick}>
                    Завершить
                </div>
            </div>
        </div>
    );
}

MultiplayerGameFinishUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    points: PropTypes.number,
    title: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(PlayUser),
};

export default MultiplayerGameFinishUI;
