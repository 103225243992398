class PlayUserModel {
    id = null;
    score = 0;
    level = 0;
    gamesWin = 0;
    gamesCount = 0;
    photoUrl = null;
    firstName = null;

    constructor(data) {
        this.id = data.id;
        this.score = data.score || 0;
        this.level = data.level || 0;
        this.gamesWin = data.games_win || 0;
        this.gamesCount = data.games_count || 0;
        this.photoUrl = data.photo || '';
        this.firstName = data.first_name || '';
    }
}

export default PlayUserModel;
