import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './app';
import user from './user';
import top from './top';
import rating from './rating';
import profile from './profile';
import shop from './shop';
import game from './game';
import dialog from './dialog';
import notification from './notification';
import money from './money';
import page from './page';
import join from './join';
import question from './question';
import questionProblem from './questionProblem';

export default (history) =>
    combineReducers({
        router: history && connectRouter(history),
        app,
        user,
        top,
        rating,
        profile,
        shop,
        game,
        dialog,
        notification,
        money,
        page,
        join,
        question,
        questionProblem,
    });
