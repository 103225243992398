import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import BoostIcon from '../../../../images/icons/boost.svg';
import BonusIcon from '../../../../images/icons/bonus.svg';

const MAP_TYPE_ICON = {
    boost: BoostIcon,
    bonus: BonusIcon,
};

function ShopGroupUI(props) {
    return (
        <div className="shop-group">
            <div className="info-block">
                <div className="image">
                    <img src={MAP_TYPE_ICON[props.type]} alt={''} />
                </div>
                <div className="info">
                    <div className="title">{props.title}</div>
                    <div className="description">{props.description}</div>
                </div>
            </div>
            <div className="items">{props.items}</div>
        </div>
    );
}

ShopGroupUI.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.element),
};

export default ShopGroupUI;
