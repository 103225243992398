import i18n from 'i18n-js';
import moment from 'moment';

import en from './en';
import ru from './ru';

i18n.fallbacks = true;

/* eslint-disable */
i18n.pluralization.ru = function (count) {
    const key =
        count % 10 == 1 && count % 100 != 11
            ? 'one'
            : [2, 3, 4].indexOf(count % 10) >= 0 && [12, 13, 14].indexOf(count % 100) < 0
            ? 'few'
            : count % 10 == 0 ||
              [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 ||
              [11, 12, 13, 14].indexOf(count % 100) >= 0
            ? 'many'
            : 'other';
    return [key];
};

/* eslint-eanble */

i18n.translations = {
    ru,
    en,
    kk: ru,
    uk: ru,
    be: ru,
};

i18n.defaultLocale = 'en';
i18n.shortLocale = i18n.defaultLocale;
i18n.locale = 'ru';

const matchLocale = i18n.locale.match(/[a-z]+_*/);

if (matchLocale && matchLocale.length) {
    i18n.shortLocale = matchLocale[0];
}

moment.locale(i18n.shortLocale);

export default i18n;
