import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { getVkBridge } from '../../utils/vkBridge';

import HeaderUI from '../../components/Header';

import { URL_HELP, URL_MONEY, URL_SHARE } from '../../config/url';
import {
    showInvite,
    showShareBox,
    logout,
    TYPE_FB,
    TYPE_VK,
    TYPE_VK_APP,
    TYPE_WS,
    goToPage,
    TYPE_YANDEX,
} from '../../store/app';
import { showDialog } from '../../store/dialog';
import { getNewQuestionsCount } from '../../store/question';
import { GROUP_VK_URL, GROUP_VK_ID } from '../../config/social';
import { isWebMobile } from '../../utils';
import { DIALOG_MANAGE_QUESTION } from '../../containers/Dialog/Types/ManageQuestion';
import { sendReachGoal, EVENT_MONEY_TOOLTIP_CLICK } from '../../store/statistic';
import { LS_MONEY_TIP_KEY } from '../../config';
import Storage from '../../utils/storage';

const MIN_AMOUNT_TO_SHOW_MONEY_TOOLTIP = 3;
const NEXT_DAYS_TO_SHOW_TOOLTIP = 5;

class Header extends PureComponent {
    constructor(props) {
        super(props);

        const { isAllowSeeAdv, isBuyMoneyAllowed } = props;
        const canDoMoneyClick = isAllowSeeAdv || isBuyMoneyAllowed;

        this.state = {
            isFullscreen: false,
            hasMoneyTooltip: false,
            canDoMoneyClick,
        };

        this.handleHelpClick = this.handleHelpClick.bind(this);
        this.handleShareClick = this.handleShareClick.bind(this);
        this.handleMoneyClick = this.handleMoneyClick.bind(this);
        this.handleInviteClick = this.handleInviteClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleGroupClick = this.handleGroupClick.bind(this);
        this.handleFullscreen = this.handleFullscreen.bind(this);
        this.handleMoneyTooltipClose = this.handleMoneyTooltipClose.bind(this);
        this.handleMoneyTooltipClick = this.handleMoneyTooltipClick.bind(this);
        this.handleManageNewQuestions = this.handleManageNewQuestions.bind(this);
    }

    componentDidMount() {
        const { isAllowSeeAdv, isBuyMoneyAllowed, user } = this.props;

        if (user.type === 'admin') {
            // Check new questions
            this.props.actions.getNewQuestionsCount();
        }

        Storage.getItem(LS_MONEY_TIP_KEY).then((result) => {
            let isDateLess = true;

            if (result) {
                isDateLess = moment(result) <= moment();
            }

            const canDoMoney = isAllowSeeAdv || isBuyMoneyAllowed;
            const hasMoneyTooltip =
                canDoMoney && isDateLess && user.money <= MIN_AMOUNT_TO_SHOW_MONEY_TOOLTIP;

            this.setState({
                hasMoneyTooltip,
            });
        });
    }

    render() {
        const { appType, user } = this.props;
        const { canDoMoneyClick, hasMoneyTooltip } = this.state;

        return (
            <HeaderUI
                user={user}
                onHelpClick={[TYPE_YANDEX].indexOf(appType) === -1 ? this.handleHelpClick : null}
                isFullscreen={this.state.isFullscreen}
                onInviteClick={
                    [TYPE_VK, TYPE_VK_APP, TYPE_FB, TYPE_WS].indexOf(appType) !== -1
                        ? this.handleInviteClick
                        : null
                }
                onMoneyClick={canDoMoneyClick ? this.handleMoneyClick : null}
                onShareClick={
                    [TYPE_VK, TYPE_FB, TYPE_VK_APP].indexOf(appType) !== -1
                        ? this.handleShareClick
                        : null
                }
                onLogoutClick={appType === TYPE_WS && this.handleLogoutClick}
                onGroupClick={
                    [TYPE_VK, TYPE_VK_APP, TYPE_WS].indexOf(appType) !== -1
                        ? this.handleGroupClick
                        : null
                }
                onFullscreenClick={
                    !isWebMobile() && [TYPE_VK, TYPE_WS].indexOf(appType) !== -1
                        ? this.handleFullscreen
                        : null
                }
                newQuestionsCount={this.props.newQuestionsCount}
                onManageNewQuestionsClick={this.handleManageNewQuestions}
                hasMoneyTooltip={hasMoneyTooltip}
                onMoneyTooltipClose={this.handleMoneyTooltipClose}
                onMoneyTooltipClick={this.handleMoneyTooltipClick}
            />
        );
    }

    handleHelpClick() {
        this.props.actions.goToPage(URL_HELP);
    }

    handleShareClick() {
        switch (this.props.appType) {
            case TYPE_VK:
            case TYPE_VK_APP:
            case TYPE_FB:
                this.props.actions.showShareBox(this.props.appType);
                break;
            default:
                this.props.actions.goToPage(URL_SHARE);
        }
    }

    handleMoneyClick() {
        this.props.actions.goToPage(URL_MONEY);
    }

    handleInviteClick() {
        this.props.actions.showInvite(this.props.appType);
    }

    handleLogoutClick() {
        this.props.actions.logout(() => {
            window.location.reload();
        });
    }

    handleGroupClick() {
        if (this.props.appType === TYPE_VK_APP) {
            getVkBridge().send('VKWebAppJoinGroup', { group_id: GROUP_VK_ID });
        } else {
            window.open(GROUP_VK_URL, '_blank');
        }
    }

    handleFullscreen() {
        const isFullscreen = document.fullscreenElement;

        if (isFullscreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            const elem = document.querySelector('body');
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        }

        this.setState({
            isFullscreen: !isFullscreen,
        });
    }

    handleManageNewQuestions() {
        this.props.actions.showDialog(DIALOG_MANAGE_QUESTION);
    }

    handleMoneyTooltipClose(event) {
        event.preventDefault();
        event.stopPropagation();

        this.closeMoneyTooltipAndSaveNewDate();
    }

    handleMoneyTooltipClick() {
        this.closeMoneyTooltipAndSaveNewDate();
        this.props.actions.sendReachGoal(EVENT_MONEY_TOOLTIP_CLICK);
        this.props.actions.goToPage(URL_MONEY);
    }

    closeMoneyTooltipAndSaveNewDate() {
        Storage.setItem(LS_MONEY_TIP_KEY, moment().add(NEXT_DAYS_TO_SHOW_TOOLTIP, 'days'));

        this.setState({
            hasMoneyTooltip: false,
        });
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
        isBuyMoneyAllowed: state.app.isBuyMoneyAllowed,
        newQuestionsCount: state.question.newQuestionsCount,
        isAllowSeeAdv: state.app.isAllowSeeAdv,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                showInvite,
                showShareBox,
                logout,
                getNewQuestionsCount,
                showDialog,
                sendReachGoal,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
