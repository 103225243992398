import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import PageGameFriendIntroUI, {
    STEP_CONNECT,
    STEP_INTO,
    STEP_NEW_GAME,
} from '../../../../components/Pages/Game/Friend';

import { URL_HOME } from '../../../../config/url';
import { showInvite, TYPE_FB, TYPE_VK, TYPE_VK_APP, TYPE_WS } from '../../../../store/app';
import { GAME_TYPE_FRIEND, MAIN_DOMAIN } from '../../../../config';

import {
    EVENT_CLIENT_CHECK_FRIEND_GAME,
    EVENT_SERVER_CHECK_FRIEND_GAME,
} from '../../../../config/socket';

import { getVkBridge } from '../../../../utils/vkBridge';

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

class FriendGame extends PureComponent {
    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);
        this.handleInviteFriend = this.handleInviteFriend.bind(this);
        this.handleNewGameStart = this.handleNewGameStart.bind(this);
        this.handleGameConnect = this.handleGameConnect.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleBackToMenu = this.handleBackToMenu.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleShareLink = this.handleShareLink.bind(this);

        this.onGameCheck = this.onGameCheck.bind(this);

        let friendGameStep = STEP_INTO;
        let gameCode = '';

        if (props.pin) {
            gameCode = props.pin;
            friendGameStep = STEP_CONNECT;
        }

        this.state = {
            gameCode,
            friendGameStep,
            isLoading: false,
            errorMessage: '',
            isWaitingFriend: false,
            isFriendGameExist: undefined,
        };
    }

    componentDidMount() {
        if (this.props.pin) {
            this.handleStart();
        }

        this.props.socket.on(EVENT_SERVER_CHECK_FRIEND_GAME, this.onGameCheck);
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_CHECK_FRIEND_GAME, this.onGameCheck);
    }

    render() {
        return (
            <PageGameFriendIntroUI
                friendGameUrl={`${MAIN_DOMAIN}/${this.state.gameCode}`}
                step={this.state.friendGameStep}
                gameCode={this.state.gameCode}
                isLoading={this.state.isLoading}
                onNewGameStart={this.handleNewGameStart}
                onGameConnect={this.handleGameConnect}
                onStart={this.handleStart}
                onBackToMenu={this.handleBackToMenu}
                onPageClose={this.handlePageClose}
                onFriendInvite={
                    [TYPE_VK, TYPE_VK_APP, TYPE_FB].indexOf(this.props.appType) !== -1
                        ? this.handleInviteFriend
                        : null
                }
                onCodeChange={this.handleCodeChange}
                errorMessage={this.state.errorMessage}
                isWaitingFriend={this.state.isWaitingFriend}
                onShareLink={
                    [TYPE_VK_APP].indexOf(this.props.appType) !== -1 ? this.handleShareLink : null
                }
                hasUseLink={[TYPE_WS].indexOf(this.props.appType) !== -1}
            />
        );
    }

    handlePageClose() {
        this.props.actions.toUrl(URL_HOME);
    }

    handleInviteFriend() {
        this.props.actions.showInvite(this.props.appType);
    }

    handleNewGameStart() {
        this.setState({
            friendGameStep: STEP_NEW_GAME,
            gameCode: getRandomInt(1000, 9999),
        });
    }

    handleGameConnect() {
        this.setState({
            friendGameStep: STEP_CONNECT,
        });
    }

    handleStart() {
        if (!this.state.isLoading) {
            if (!this.state.gameCode) {
                this.setState({
                    errorMessage: 'Введите код игры',
                });
            } else {
                this.setState({
                    isLoading: true,
                    errorMessage: '',
                });

                if (this.state.friendGameStep === STEP_CONNECT) {
                    // Check game for existing before start
                    this.checkIfFriendGameExits(this.state.gameCode);
                } else {
                    this.setState({ isWaitingFriend: true });
                    this.props.onStart(GAME_TYPE_FRIEND, this.state.gameCode);
                }
            }
        }
    }

    handleBackToMenu() {
        if (!this.state.isLoading) {
            this.setState({
                friendGameStep: STEP_INTO,
                gameCode: '',
                errorMessage: '',
            });
        }
    }

    handleCodeChange(event) {
        this.setState({
            gameCode: event.target.value,
        });
    }

    handleShareLink() {
        switch (this.props.appType) {
            case TYPE_VK_APP:
                getVkBridge().send('VKWebAppShare', {
                    link: `${MAIN_DOMAIN}/${this.state.gameCode}`,
                });
                break;
            default:
        }
    }

    checkIfFriendGameExits(pin) {
        this.props.socket.emit(EVENT_CLIENT_CHECK_FRIEND_GAME, {
            pin: Number(pin),
        });
    }

    onGameCheck({ result, game }) {
        if (result) {
            this.props.onStart(GAME_TYPE_FRIEND, this.state.gameCode, game.game_id);
        } else {
            this.setState({
                isLoading: false,
                errorMessage: 'Такая игра не найдена',
            });
        }
    }
}

FriendGame.propTypes = {
    onStart: PropTypes.func.isRequired,
    navigation: PropTypes.object,
    pin: PropTypes.string,
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
        socket: state.app.socket,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
                showInvite,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendGame);
