import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DialogAddQuestionUI from '../../../../components/Dialog/Types/AddQuestion';
import { hideDialog } from '../../../../store/dialog';
import { addQuestion } from '../../../../store/question';

export const DIALOG_ADD_QUESTION = 'add-question';

class DialogAddQuestion extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isQuestionAdded: false,
        };

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleOneMoreClick = this.handleOneMoreClick.bind(this);
    }

    render() {
        return (
            <DialogAddQuestionUI
                onContinueClick={this.handleContinueClick}
                onCancelClick={this.handleCancelClick}
                onAddOneMoreClick={this.handleOneMoreClick}
                isLoading={this.state.isLoading}
                isQuestionAdded={this.state.isQuestionAdded}
            />
        );
    }

    handleContinueClick({ question, answer1, answer2, answer3, answer4, level, rightAnswer }) {
        if (this.state.isLoading) {
            return false;
        }

        this.setState({
            isLoading: true,
        });

        this.props.actions
            .addQuestion({
                question,
                answer1,
                answer2,
                answer3,
                answer4,
                level,
                rightAnswer,
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                    isQuestionAdded: true,
                });
            })
            .catch((result) => {
                const message = (result && result.error) || 'Ошибка добавления вопроса';
                // eslint-disable-next-line no-alert
                alert(message);
                this.setState({
                    isLoading: false,
                    isQuestionAdded: false,
                });
            });
    }

    handleCancelClick() {
        this.props.actions.hideDialog();
    }

    handleOneMoreClick() {
        this.setState({
            isQuestionAdded: false,
        });
    }
}

const mapStateToProps = function (state) {
    return {
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                addQuestion,
                hideDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddQuestion);
