export const EVENT_SERVER_READY = 'ready';
export const EVENT_SERVER_GAME_STARTED = 'game:start'; // game:started
export const EVENT_SERVER_GAME_TIMER = 'game:timer';
export const EVENT_SERVER_GAME_SINGLE_FINISHED = 'game:singleFinished';
export const EVENT_SERVER_MULTIPLAYER_GAME_FINISHED = 'game:multiplayerFinished';
export const EVENT_SERVER_GAME_NEW_ROUND = 'game:newRound';
export const EVENT_SERVER_GAME_ROUND_RESULT = 'game:roundResult';
export const EVENT_SERVER_GAME_UPDATE_HELPERS = 'game:updateHelpers';
export const EVENT_SERVER_GAME_HELPER_DATA = 'game:helperData';
export const EVENT_SERVER_GAME_ACTIVE_USER_ID = 'game:setActiveUserId';
export const EVENT_SERVER_GAME_USER_LEFT_GAME = 'game:userLeftGame';
export const EVENT_SERVER_UPDATE_USER = 'user:update';
export const EVENT_SERVER_GAME_ONLINE = 'game:online';
export const EVENT_SERVER_USER_ANSWERED = 'round:hasAnswer';
export const EVENT_SERVER_SHOW_RIGHT_ANSWER = 'round:rightAnswer';
export const EVENT_SERVER_ROUND_COMPLETED = 'round:complete';
export const EVENT_SERVER_GAME_HELPER = 'game:helper';
export const EVENT_SERVER_GAME_FINISHED = 'game:end';
export const EVENT_SERVER_USED_HELPER = 'game:usedHelper';
export const EVENT_SERVER_GAME_DISCONNECTED = 'game:disconnect';
export const EVENT_SERVER_NOTIFY_ROUND_WINNER = 'chat:winRoundUser';
export const EVENT_SERVER_UPDATE_MONEY = 'user:money';
export const EVENT_SERVER_GAME_CREATED = 'game:created';
export const EVENT_SERVER_CHECK_FRIEND_GAME = 'game:checkFriendGame';

export const EVENT_SERVER_UPDATE_USER_OLD = 'update:user'; //user:update

export const EVENT_CLIENT_START_NEW_GAME = 'game:init'; //  game:new
export const EVENT_CLIENT_GAME_LEAVE = 'game:leaveRoom'; //  game:leave
export const EVENT_CLIENT_GAME_PLAYER_READY = 'game:start'; // game:playerReady
export const EVENT_CLIENT_GAME_USE_HELPER = 'game:helper';
export const EVENT_CLIENT_LEAVE = 'leave';
export const EVENT_CLIENT_USER_ANSWERED = 'round:answer';
export const EVENT_CLIENT_START_GAME_WITH_BOT = 'game:bot';
export const EVENT_CLIENT_CHECK_FRIEND_GAME = 'game:checkFriendGame';
