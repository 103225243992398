import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import MultiplayerGameFinishUI from '../../../../components/Dialog/Types/MultiplayerGameFinish';
import { hideDialog } from '../../../../store/dialog';
import { showAdv } from '../../../../store/app';
import { URL_HOME } from '../../../../config/url';
import PlayUser from '../../../../model/playUser';
import I18n from '../../../../i18n';

import { randomIntFromInterval } from '../../../../utils';
import { ADV_INTERSTITIAL, SEE_ADV_PERCENT } from '../../../../config';

export const DIALOG_MULTIPLAYER_GAME_FINISH = 'multiplayer-game-finish';

class DialogMultiplayerGameFinish extends PureComponent {
    constructor(props) {
        super(props);

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    render() {
        let title;
        const { winUser, isPlayerWinner, earnPoints } = this.props;

        if (winUser) {
            if (isPlayerWinner) {
                title = I18n.t('youWinTitle');
            } else {
                title = I18n.t('winUserTitle');
            }
        } else {
            title = I18n.t('winUserTitle');
        }

        return (
            <MultiplayerGameFinishUI
                points={earnPoints}
                onContinueClick={this.handleContinueClick}
                onCancelClick={this.handleCancelClick}
                title={title}
                user={winUser}
            />
        );
    }

    handleContinueClick() {
        this.props.onNewGame();
        this.props.actions.hideDialog();
    }

    handleCancelClick() {
        this.props.actions.hideDialog();
        this.props.actions.toUrl(URL_HOME);

        if (this.props.isAllowSeeAdv && randomIntFromInterval(0, 100) < SEE_ADV_PERCENT) {
            this.props.actions
                .showAdv(ADV_INTERSTITIAL, this.props.user.externalId)
                .catch(() => {});
        }
    }
}

DialogMultiplayerGameFinish.propTypes = {
    isPlayerWinner: PropTypes.bool.isRequired,
    onNewGame: PropTypes.func.isRequired,
    winUser: PropTypes.instanceOf(PlayUser),
    earnPoints: PropTypes.number,
};

const mapStateToProps = function (state) {
    return {
        isAllowSeeAdv: state.app.isAllowSeeAdv,
        user: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
                hideDialog,
                showAdv,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogMultiplayerGameFinish);
