export const HELPER_1_4 = {
    id: '1_4',
};

export const HELPER_1_2 = {
    id: '1_2',
};

export const HELPER_MAGIC = {
    id: 'magic',
};

export const HELPER_TIME = {
    id: 'time',
};
