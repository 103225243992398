import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import HomeUI from '../../components/Home';
import FooterUI from '../../components/Footer';

import PageDefault from '../../containers/Pages/Default';
import PageRating from '../../containers/Pages/Rating';
import PageHelp from '../../containers/Pages/Help';
import PageShare from '../../containers/Pages/Share';
import PageMoney from '../../containers/Pages/Money';
import PageProfile from '../../containers/Pages/Profile';
import PageUser from '../../containers/Pages/User';
import PageShop from '../../containers/Pages/Shop';
import PageGame from '../../containers/Pages/Game';

import Header from '../Header';
import Dialog from '../Dialog';
import TopUsers from '../TopUsers';
import Notification from '../Notification';

import { TYPE_OK, TYPE_YANDEX, TYPE_VK, setOnlineUsersCount } from '../../store/app';
import { IOS_GAME_LINK, ANDROID_GAME_LINK } from '../../config';
import { GROUP_VK_URL, GROUP_OK_URL } from '../../config/social';
import {
    URL_HOME,
    URL_HELP,
    URL_MONEY,
    URL_SHARE,
    URL_RATING,
    URL_PROFILE,
    URL_SHOP,
    URL_GAME,
} from '../../config/url';
import {
    EVENT_SERVER_UPDATE_USER,
    EVENT_SERVER_GAME_ONLINE,
    EVENT_SERVER_UPDATE_MONEY,
    EVENT_SERVER_UPDATE_USER_OLD,
} from '../../config/socket';
import { setUser, setProfileMoney } from '../../store/profile';
import { showDialog } from '../../store/dialog';
import { DIALOG_UPDATE_USER_DETAILS } from '../Dialog/Types/UpdateUserDetails';

class Home extends PureComponent {
    constructor(props) {
        super(props);

        this.onUserUpdated = this.onUserUpdated.bind(this);
        this.onUserUpdatedOld = this.onUserUpdatedOld.bind(this);
        this.onOnlineUpdated = this.onOnlineUpdated.bind(this);
        this.onMoneyUpdated = this.onMoneyUpdated.bind(this);
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_UPDATE_USER_OLD, this.onUserUpdatedOld);
        this.props.socket.on(EVENT_SERVER_UPDATE_USER, this.onUserUpdated);
        this.props.socket.on(EVENT_SERVER_GAME_ONLINE, this.onOnlineUpdated);
        this.props.socket.on(EVENT_SERVER_UPDATE_MONEY, this.onMoneyUpdated);

        if (this.props.user.isNeedUpdateProfile === 'Y') {
            this.props.actions.showDialog(DIALOG_UPDATE_USER_DETAILS, { isRequired: true });
        }
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_UPDATE_USER_OLD, this.onUserUpdatedOld);
        this.props.socket.off(EVENT_SERVER_UPDATE_USER, this.onUserUpdated);
        this.props.socket.off(EVENT_SERVER_GAME_ONLINE, this.onOnlineUpdated);
        this.props.socket.off(EVENT_SERVER_UPDATE_MONEY, this.onMoneyUpdated);
    }

    render() {
        let locationKey = this.props.location.key;

        if (new RegExp(URL_RATING).test(this.props.location.pathname)) {
            locationKey = URL_RATING;
        }

        return (
            <HomeUI
                header={<Header />}
                sidebar={this.getSidebar()}
                footer={this.getFooter()}
                dialog={<Dialog />}
                isGameStarted={this.props.isGameStarted}
                notification={<Notification />}
                locationKey={locationKey}
            >
                <Switch location={this.props.location}>
                    <Route path={`${URL_RATING}/:filter?`} component={PageRating} />
                    <Route path={URL_HELP} component={PageHelp} />
                    <Route path={URL_SHARE} component={PageShare} />
                    <Route path={URL_MONEY} component={PageMoney} />
                    <Route path={`${URL_PROFILE}/:userId`} component={PageUser} />
                    <Route path={URL_PROFILE} component={PageProfile} />
                    <Route path={URL_SHOP} component={PageShop} />
                    <Route path={`${URL_GAME}/:type?/:pin?`} component={PageGame} />
                    <Route exact path={URL_HOME} component={PageDefault} />
                    <Route component={PageDefault} />
                </Switch>
            </HomeUI>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    getSidebar() {
        return <TopUsers />;
    }

    getFooter() {
        const { appType } = this.props;

        let groupUrl;
        let iosLink = IOS_GAME_LINK;
        let androidLink = ANDROID_GAME_LINK;

        switch (this.props.user.appType) {
            case TYPE_OK:
                groupUrl = GROUP_OK_URL;
                break;
            default:
                groupUrl = GROUP_VK_URL;
                break;
        }

        if ([TYPE_YANDEX].indexOf(appType) !== -1) {
            iosLink = undefined;
            androidLink = undefined;
            groupUrl = undefined;
        }

        if ([TYPE_VK].indexOf(appType) !== -1) {
            // Temporary
            iosLink = undefined;
            androidLink = undefined;
        }

        return <FooterUI groupUrl={groupUrl} iosLink={iosLink} androidLink={androidLink} />;
    }

    onUserUpdated(user) {
        this.props.actions.setUser(user);
    }

    onUserUpdatedOld(result) {
        if (result.updateInfo && result.updateInfo.user) {
            this.props.actions.setUser(result.updateInfo.user);
        }
    }

    onOnlineUpdated(result) {
        this.props.actions.setOnlineUsersCount(result.onlineTotal || result.online);
    }

    onMoneyUpdated(result) {
        this.props.actions.setProfileMoney(result.money);
    }
}

Home.propTypes = {};

const mapStateToProps = function (state) {
    return {
        socket: state.app.socket,
        user: state.profile.info,
        isGameStarted: state.game.isGameStarted,
        appType: state.app.appType,
        location: state.router.location,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setUser,
                setOnlineUsersCount,
                setProfileMoney,
                showDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
