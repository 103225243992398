import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageCountDownUI from '../../../../components/Pages/Game/Countdown';
import PlayFieldUI from '../../../../components/Pages/Game/PlayField';

import HelpersPanel from './HelpersPanel';
import GamePanel from './GamePanel';
import QuestionPanel from './QuestionPanel';

import {
    EVENT_CLIENT_GAME_LEAVE,
    EVENT_CLIENT_GAME_PLAYER_READY,
    EVENT_SERVER_USED_HELPER,
    EVENT_SERVER_USER_ANSWERED,
    EVENT_SERVER_NOTIFY_ROUND_WINNER,
} from '../../../../config/socket';
import {
    setRound,
    setPlayUser,
    removeUsersStatistic,
    setActiveUserId,
} from '../../../../store/game';
import { showNotification } from '../../../../store/notification';
import { goToPage } from '../../../../store/app';
import { URL_HOME } from '../../../../config/url';

const ONE_SECOND = 1000;
const NOTIFICATION_TIMER = 1000;

class PlayGame extends PureComponent {
    constructor(props) {
        super(props);

        this.notificationTimer = null;

        this.state = {
            startGameCountdownTimer: 3,
            notification: '',
        };

        this.onGameUsedHelper = this.onGameUsedHelper.bind(this);
        this.onUserAnswered = this.onUserAnswered.bind(this);
        this.onNotifyRoundWinner = this.onNotifyRoundWinner.bind(this);
        this.handlePageClose = this.handlePageClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.startGameCountdownTimer === 0 && this.state.startGameCountdownTimer > 0) {
            this.setTimerValue();
        }

        if (prevState.startGameCountdownTimer > 0 && this.state.startGameCountdownTimer === 0) {
            this.sendPlayerReady();
        }
    }

    componentDidMount() {
        this.setTimerValue();
        this.props.socket.on(EVENT_SERVER_USED_HELPER, this.onGameUsedHelper);
        this.props.socket.on(EVENT_SERVER_USER_ANSWERED, this.onUserAnswered);
        this.props.socket.on(EVENT_SERVER_NOTIFY_ROUND_WINNER, this.onNotifyRoundWinner);
    }

    componentWillUnmount() {
        clearTimeout(this.notificationTimer);
        clearTimeout(this.botHelperTimer);

        this.props.actions.removeUsersStatistic();
        this.props.actions.setActiveUserId(null);

        this.props.socket.off(EVENT_SERVER_USED_HELPER, this.onGameUsedHelper);
        this.props.socket.off(EVENT_SERVER_USER_ANSWERED, this.onUserAnswered);
        this.props.socket.off(EVENT_SERVER_NOTIFY_ROUND_WINNER, this.onNotifyRoundWinner);

        const userId = this.props.user.id;
        const gameRoomId = this.props.game.roomId;

        this.props.socket.emit(EVENT_CLIENT_GAME_LEAVE, {
            gameRoomId,
            userId,
        });
    }

    render() {
        if (this.state.startGameCountdownTimer === 0) {
            return (
                <PlayFieldUI
                    gamePanel={this.renderGamePanel()}
                    helpersPanel={<HelpersPanel />}
                    questionPanel={this.renderQuestionPanel()}
                    isActive={this.props.user.id === this.props.activeUserId}
                    onPageClose={this.handlePageClose}
                />
            );
        } else {
            return <PageCountDownUI value={this.state.startGameCountdownTimer} />;
        }
    }

    renderGamePanel() {
        return (
            <GamePanel
                playerUserStat={this.props.playerUserStat}
                opponentUserStat={this.props.opponentUserStat}
                activeUserId={this.props.activeUserId}
                roundValue={this.props.roundId}
                notification={this.state.notification}
            />
        );
    }

    renderQuestionPanel() {
        return <QuestionPanel />;
    }

    /**
     * Send player is ready when animation is finished
     */
    sendPlayerReady() {
        this.props.socket.emit(EVENT_CLIENT_GAME_PLAYER_READY, {
            gameId: this.props.game.id,
            userId: this.props.user.id,
            // obsolete field
            gameRoomId: this.props.game.roomId,
        });
    }

    setTimerValue() {
        if (this.state.startGameCountdownTimer > 0) {
            setTimeout(() => {
                this.setState({
                    startGameCountdownTimer: this.state.startGameCountdownTimer - 1,
                });

                this.setTimerValue();
            }, ONE_SECOND);
        }
    }

    /**
     * Someone used helper in game
     * @param response
     * @param response.userId
     * @param response.helper
     */
    onGameUsedHelper(response) {
        if (Number(response.userId) !== Number(this.props.user.id)) {
            this.showNotification('Соперник использовал подсказку');
        }
    }

    /**
     * @param response
     * @param response.userId
     */
    onUserAnswered(response) {
        // Only when opponent answered
        if (Number(response.userId) !== Number(this.props.user.id)) {
            this.showNotification('Соперник ответил');
        }
    }

    /**
     *
     * @param response
     * @param {Number|String} response.winUserId
     */
    onNotifyRoundWinner(response) {
        const { playerUserStat, user, opponentUserStat } = this.props;

        let message;

        if (response.winUserId === 'none') {
            message = 'Ничья в этом раунде';
        } else {
            let firstName;

            if (Number(response.winUserId) === Number(user.id)) {
                firstName = playerUserStat.firstName;
            } else {
                firstName = opponentUserStat.firstName;
            }

            message = `Раунд выиграл игрок ${firstName}`;
        }

        this.showNotification(message);
    }

    showNotification(notification) {
        clearTimeout(this.notificationTimer);

        this.setState({
            notification,
        });

        this.notificationTimer = setTimeout(() => {
            this.setState({
                notification: null,
            });
        }, NOTIFICATION_TIMER);
    }

    handlePageClose() {
        this.props.actions.goToPage(URL_HOME);
    }
}

PlayGame.propTypes = {};

const mapStateToProps = function (state) {
    const user = state.profile.info;
    const game = state.game.game;
    let playerUserStat;
    let opponentUserStat;

    if (Number(game.firstUserId) === Number(user.id)) {
        playerUserStat = state.game.usersStatistic[game.firstUserId];
        opponentUserStat = state.game.usersStatistic[game.secondUserId];
    } else {
        playerUserStat = state.game.usersStatistic[game.secondUserId];
        opponentUserStat = state.game.usersStatistic[game.firstUserId];
    }

    return {
        user,
        socket: state.app.socket,
        appType: state.app.appType,
        roundId: state.game.roundId,
        game,
        playerUserStat,
        opponentUserStat,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setRound,
                setPlayUser,
                removeUsersStatistic,
                setActiveUserId,
                showNotification,
                goToPage,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayGame);
