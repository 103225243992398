import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import PageGameLoadingUI from '../../../../components/Pages/Game/Loading';
import ButtonUI from '../../../../components/UI/Button';

import { GAME_TYPE_RANDOM } from '../../../../config';

import { URL_HOME } from '../../../../config/url';
import I18n from '../../../../i18n';
import { TYPE_NATIVE } from '../../../../store/app';
import Style, { CANCEL_BUTTON_GRADIENT } from '../../../../style';

class RandomGame extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };

        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.onStart(GAME_TYPE_RANDOM);
    }

    componentWillUnmount() {}

    render() {
        return (
            <PageGameLoadingUI
                titleText={I18n.t('opponentSearch')}
                descriptionText={I18n.t('waitingSecondPlayer')}
                buttons={[
                    <ButtonUI
                        key={'cancel'}
                        text={I18n.t('cancelIt')}
                        className={'small-inline'}
                        onClick={this.handleCancelClick}
                        colors={CANCEL_BUTTON_GRADIENT}
                        containerStyle={Style.Button100}
                    />,
                ]}
            />
        );
    }

    handleCancelClick() {
        if (this.props.appType === TYPE_NATIVE) {
            this.props.navigation.navigate(URL_HOME);
        } else {
            this.props.actions.toUrl(URL_HOME);
        }
    }
}

RandomGame.propTypes = {
    onStart: PropTypes.func.isRequired,
    navigation: PropTypes.object,
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RandomGame);
