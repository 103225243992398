import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../../../UI/Button';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';

function UserDisconnectedUI(props) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-round-finished': true,
        'dialog-user-disconnected': true,
    });

    return (
        <div className={classes}>
            <h2>Соперник отключился</h2>

            <div className="content">
                <div className={'game-info'}>
                    <i className="icon">
                        <FontAwesomeIcon icon={faFrown} />
                    </i>
                </div>

                <div className="text-block">Игрок вышел из игры, ему будет засчитано нарушение</div>
            </div>
            <div className={'buttons'}>
                <Button text={'Новая игра'} onClick={props.onContinueClick} />
                <div className="sub-button" onClick={props.onCancelClick}>
                    Завершить
                </div>
            </div>
        </div>
    );
}

UserDisconnectedUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};
export default UserDisconnectedUI;
