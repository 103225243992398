import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import PageCross from '../../../UI/PageCross';

function PlayFieldUI({ onPageClose, gamePanel, helpersPanel, questionPanel }) {
    return (
        <div id="page-game-playfield" className="page">
            {onPageClose ? <PageCross onClick={onPageClose} /> : null}
            <div className="play-container">
                {gamePanel}
                <div className="game-field">{questionPanel}</div>
            </div>
            <div className="helpers-panel-wrapper">{helpersPanel}</div>
        </div>
    );
}

PlayFieldUI.propTypes = {
    gamePanel: PropTypes.element.isRequired,
    helpersPanel: PropTypes.element.isRequired,
    questionPanel: PropTypes.element.isRequired,
    isActive: PropTypes.bool.isRequired,
    progressLeft: PropTypes.number,
    onPageClose: PropTypes.func,
};

export default PlayFieldUI;
