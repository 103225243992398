import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function MoneyItemUI(props) {
    return (
        <div className={['money-item', 'hover-item', props.type].join(' ')} onClick={props.onClick}>
            <div className="icon">
                <img src={props.iconUrl} alt={props.price} />
            </div>
            <div className="description">
                <div className="title">{props.title}</div>
                <div className="price">
                    {props.price}
                    {props.oldPrice ? (
                        <span className="old-price">&nbsp;{props.oldPrice}&nbsp;</span>
                    ) : null}
                </div>
                {props.helperText ? (
                    <div className="helper-text">
                        <span>{props.helperText}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

MoneyItemUI.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    oldPrice: PropTypes.string,
    type: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    iconUrl: PropTypes.string.isRequired,
};

export default MoneyItemUI;
