const STORAGE_NAME = 'AppStorage';

class Storage {
    getItem(key) {
        return new Promise((resolve, reject) => {
            try {
                const item = window.localStorage.getItem(this._getKey(key));
                resolve(item);
            } catch (error) {
                reject(new Error(`Storage: can not get item`));
            }
        });
    }

    setItem(key, value) {
        return new Promise((resolve, reject) => {
            try {
                window.localStorage.setItem(this._getKey(key), value);
                resolve();
            } catch (error) {
                reject(new Error(`Storage: can not insert item`));
            }
        });
    }

    /**
     *
     * @param {object} items
     * @returns {*|Promise}
     */
    setItems(items = {}) {
        return new Promise((resolve) => {
            Object.keys(items).forEach((key) => {
                window.localStorage.setItem(this._getKey(key), items[key]);
            });

            resolve();
        });
    }

    /**
     *
     * @param {Array|String} keys
     */
    remove(keys) {
        try {
            if (Array.isArray(keys)) {
                keys.map(this._getKey).forEach((itemKey) => {
                    window.localStorage.removeItem(itemKey);
                });
            } else {
                window.localStorage.removeItem(this._getKey(keys));
            }
        } catch (error) {
            console.log(`Storage: can not insert items`);
        }
    }

    _getKey(key) {
        return `@${STORAGE_NAME}:${key}`;
    }
}

export default new Storage();
