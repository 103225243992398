import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './style.scss';
import PlayUser from '../../../../model/playUser';
import UserInfoUI from '../../../UI/UserInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faTrophy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { numberWithSpaces } from '../../../../utils';

import i18n from '../../../../i18n';

const renderUser = (user) => {
    return (
        <div className="user-game-info">
            <div className="user-name">{user.firstName}</div>
            <div className="games-info">
                <div className="info-item games-win">
                    <i className="icon">
                        <FontAwesomeIcon icon={faTrophy} />
                    </i>
                    <span>{numberWithSpaces(user.gamesWin)}</span>
                </div>
                <div className="info-item games-played">
                    <i className="icon">
                        <FontAwesomeIcon icon={faGamepad} />
                    </i>
                    <span>{numberWithSpaces(user.gamesCount)}</span>
                </div>
            </div>
        </div>
    );
};

function MultiplayerGamePanelUI(props) {
    const { playerUserStat, opponentUserStat, roundValue } = props;

    return (
        <div id="multiplayer-game-panel">
            <div className="users">
                <div className="first-user user">
                    {renderUser(playerUserStat)}
                    <UserInfoUI photoUrl={playerUserStat.photoUrl} level={playerUserStat.level} />
                </div>
                <div className="round">
                    <div className="score">
                        {playerUserStat.score}:{opponentUserStat.score}
                    </div>
                    <div className="round-value">{i18n.t('roundValue', { value: roundValue })}</div>
                </div>
                <div className="second-user user">
                    <UserInfoUI
                        photoUrl={opponentUserStat.photoUrl}
                        level={opponentUserStat.level}
                    />
                    {renderUser(opponentUserStat)}
                </div>
            </div>
            <div className="notification-block">
                <TransitionGroup className="in-notification">
                    <CSSTransition
                        key={props.notification}
                        classNames="action"
                        appear={true}
                        timeout={200}
                        enter={true}
                        exit={true}
                    >
                        <div className="internal-content">
                            {props.notification ? (
                                <div className="notification-content">
                                    <i className="icon">
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </i>
                                    <span>{props.notification}</span>
                                </div>
                            ) : null}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
}

MultiplayerGamePanelUI.propTypes = {
    playerUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    opponentUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    activeUserId: PropTypes.number,
    roundValue: PropTypes.number,
    notification: PropTypes.string,
};

export default MultiplayerGamePanelUI;
