class GameUserModel {
    id = null;
    firstName = null;
    lastName = null;
    score = null;
    points = null;
    photoUrl = null;
    attempts = null;
    rightAttempts = null;

    constructor(userData) {
        this.id = userData.id;
        this.firstName = userData.first_name;
        this.lastName = userData.last_name;
        this.score = userData.score;
        this.points = userData.points;
        this.photoUrl = userData.photo;
        this.attempts = userData.attempts;
        this.rightAttempts = userData.rightAttempts;
    }
}

export default GameUserModel;
