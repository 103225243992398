import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import UserModel from '../../model/user';
import UserLevelProgressUI from '../UI/LevelProgress';
import UserInfoUI from '../UI/UserInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSignOutAlt,
    faQuestionCircle,
    faUserPlus,
    faShareSquare,
    faUsers,
    faMinusSquare,
    faPlusSquare,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';

import i18n from '../../i18n';
import CoinsIcon from '../../images/icons/coins.svg';

function HeaderUI(props) {
    const { user, hasMoneyTooltip, onMoneyTooltipClick, onMoneyTooltipClose } = props;

    return (
        <div className="user-header">
            <UserInfoUI photoUrl={user.photoUrl} level={user.level} />

            <UserLevelProgressUI
                levelPoints={props.user.levelPoints}
                levelNeedPoints={props.user.levelNeedPoints}
                totalPoints={props.user.totalPoints}
            />

            <div
                className={classNames({ 'user-money': true, 'no-action': !props.onMoneyClick })}
                onClick={props.onMoneyClick ? props.onMoneyClick : null}
            >
                <img className="icon-money" src={CoinsIcon} alt="" />
                <span className="text">{i18n.t('coinsCount', { count: props.user.money })}</span>
                {hasMoneyTooltip ? (
                    <div className="money-info" onClick={onMoneyTooltipClick}>
                        <span className="money-info-text">
                            Мало монет? <br /> Добавь для подсказок
                        </span>
                        <span className="money-info-close" onClick={onMoneyTooltipClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </div>
                ) : null}
            </div>

            <div className="game-block">
                <div className="game-block-items">
                    {props.newQuestionsCount ? (
                        <div
                            onClick={props.onManageNewQuestionsClick}
                            className={'item game-new-questions'}
                        >
                            <i className="icon">{props.newQuestionsCount}</i>
                            <span>Новые вопросы</span>
                        </div>
                    ) : null}

                    {props.onGroupClick ? (
                        <div onClick={props.onGroupClick} className={'item game-group'}>
                            <i className="icon">
                                <FontAwesomeIcon icon={faUsers} />
                            </i>
                            <span>Вступить</span>
                        </div>
                    ) : null}

                    {props.onShareClick ? (
                        <div onClick={props.onShareClick} className={'item game-help'}>
                            <i className="icon">
                                <FontAwesomeIcon icon={faShareSquare} />
                            </i>
                            <span>Поделиться</span>
                        </div>
                    ) : null}

                    {props.onInviteClick ? (
                        <div onClick={props.onInviteClick} className="item game-invite">
                            <i className="icon">
                                <FontAwesomeIcon icon={faUserPlus} />
                            </i>
                            <span>Пригласить</span>
                        </div>
                    ) : null}

                    {props.onFullscreenClick ? (
                        <div onClick={props.onFullscreenClick} className="item game-increase">
                            {props.isFullscreen ? (
                                <>
                                    <i className="icon">
                                        <FontAwesomeIcon icon={faMinusSquare} />
                                    </i>
                                    <span>Уменьшить</span>
                                </>
                            ) : (
                                <>
                                    <i className="icon">
                                        <FontAwesomeIcon icon={faPlusSquare} />
                                    </i>
                                    <span>Увеличить</span>
                                </>
                            )}
                        </div>
                    ) : null}

                    {props.onHelpClick ? (
                        <div onClick={props.onHelpClick} className={'item game-help'}>
                            <i className="icon">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </i>
                            <span>Помощь</span>
                        </div>
                    ) : null}

                    {props.onLogoutClick ? (
                        <div onClick={props.onLogoutClick} className="item game-logout">
                            <i className="icon">
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </i>
                            <span>Выход</span>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

HeaderUI.propTypes = {
    user: PropTypes.instanceOf(UserModel),
    onShareClick: PropTypes.func,
    onInviteClick: PropTypes.func,
    onHelpClick: PropTypes.func,
    onMoneyClick: PropTypes.func,
    onLogoutClick: PropTypes.func,
    onGroupClick: PropTypes.func,
    onFullscreenClick: PropTypes.func,
    newQuestionsCount: PropTypes.number,
    onManageNewQuestionsClick: PropTypes.func,
    hasMoneyTooltip: PropTypes.bool,
    onMoneyTooltipClose: PropTypes.func,
    onMoneyTooltipClick: PropTypes.func,
};

export default HeaderUI;
