import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QuestionAnswersUI from '../../../../../components/Pages/Game/QuestionAnswers';

import {
    EVENT_SERVER_GAME_TIMER,
    EVENT_SERVER_GAME_NEW_ROUND,
    EVENT_SERVER_SHOW_RIGHT_ANSWER,
    EVENT_CLIENT_USER_ANSWERED,
    EVENT_SERVER_GAME_HELPER,
} from '../../../../../config/socket';

import { HELPER_1_2, HELPER_1_4, HELPER_MAGIC, HELPER_TIME } from '../../../../../config/helpers';
import { MIN_LEVEL_TO_RATE_QUESTION } from '../../../../../config';
import { setTimerValue, setRound, removeAnswers } from '../../../../../store/game';
import { sendQuestionEmotion } from '../../../../../store/question';
import { sendQuestionProblem } from '../../../../../store/questionProblem';

import RoundModel from '../../../../../model/round';

const getAuthorName = (round) => {
    if (round.author && round.author.firstName) {
        if (round.author.firstName && round.author.lastName) {
            return `${round.author.firstName[0]}. ${round.author.lastName}`;
        } else {
            return round.author.firstName;
        }
    }

    return undefined;
};

// eslint-disable-next-line max-statements
const QuestionPanel = () => {
    const dispatch = useDispatch();
    const socket = useSelector((state) => state.app.socket);
    const user = useSelector((state) => state.profile.info);
    const gameState = useSelector((state) => state.game);
    const showRightAnswerTimer = useRef(0);

    const roundTimeout = gameState.roundTimeout;
    const timer = gameState.timer;
    const round = gameState.round;
    const game = gameState.game;

    const [selectedAnswer, setSelectedAnswer] = useState(undefined);
    const [rightAnswer, setRightAnswer] = useState(undefined);
    const [problemSent, setProblemSent] = useState(false);

    const progressLeft = Math.round((timer * 100) / roundTimeout);

    const sendAnswerToServer = useCallback(
        (userId, answerId) => {
            socket.emit(EVENT_CLIENT_USER_ANSWERED, {
                roundId: round.id,
                roomId: game.roomId,
                gameId: game.id,
                answer: answerId,
                userId,
            });
        },
        [game, socket, round.id]
    );

    const handleAnswerClick = useCallback(
        (userId, answerId) => {
            setSelectedAnswer(answerId);
            sendAnswerToServer(userId, answerId);
        },
        [sendAnswerToServer]
    );

    const handleQuestionLikeClick = useCallback(() => {
        dispatch(sendQuestionEmotion(round.questionId, 'like'));
    }, [round, dispatch]);

    const handleQuestionDislikeClick = useCallback(() => {
        dispatch(sendQuestionEmotion(round.questionId, 'dislike'));
    }, [round, dispatch]);

    const handleSendQuestionProblem = useCallback(() => {
        setProblemSent(true);
        dispatch(sendQuestionProblem(round.questionId, ''));
    }, [round, dispatch]);

    const onNewRound = useCallback(
        (newRound) => {
            clearTimeout(showRightAnswerTimer.current);
            setSelectedAnswer(undefined);
            setRightAnswer(undefined);
            setProblemSent(false);

            dispatch(setRound(newRound.roundId, new RoundModel(newRound.round)));
        },
        [dispatch]
    );

    const onTimer = useCallback(
        (response) => {
            dispatch(setTimerValue(response.value));
        },
        [dispatch]
    );

    const onShowRightAnswer = useCallback(
        (response) => {
            clearTimeout(showRightAnswerTimer.current);

            if (Number(response.userId) === Number(user.id)) {
                showRightAnswerTimer.current = setTimeout(() => {
                    setRightAnswer(response.rightAnswer);
                }, 200);
            }
        },
        [user]
    );

    const onUserUsedGameHelper = useCallback(
        (response) => {
            if (Number(response.userId) === Number(user.id)) {
                switch (response.helper) {
                    case HELPER_1_4.id:
                        dispatch(removeAnswers(response.removeAnswers));
                        break;
                    case HELPER_TIME.id:
                        dispatch(setTimerValue(response.time));
                        break;
                    case HELPER_1_2.id:
                        dispatch(removeAnswers(response.removeAnswers));
                        break;
                    case HELPER_MAGIC.id:
                        handleAnswerClick(user.id, response.answer);
                        break;
                    default:
                }
            }
        },
        [dispatch, handleAnswerClick, user]
    );

    useEffect(() => {
        socket.on(EVENT_SERVER_GAME_TIMER, onTimer);
        socket.on(EVENT_SERVER_GAME_NEW_ROUND, onNewRound);
        socket.on(EVENT_SERVER_SHOW_RIGHT_ANSWER, onShowRightAnswer);
        socket.on(EVENT_SERVER_GAME_HELPER, onUserUsedGameHelper);

        return () => {
            socket.off(EVENT_SERVER_GAME_TIMER, onTimer);
            socket.off(EVENT_SERVER_GAME_NEW_ROUND, onNewRound);
            socket.off(EVENT_SERVER_SHOW_RIGHT_ANSWER, onShowRightAnswer);
            socket.off(EVENT_SERVER_GAME_HELPER, onUserUsedGameHelper);
        };
    }, [socket, onTimer, onNewRound, onShowRightAnswer, onUserUsedGameHelper]);

    useEffect(() => {
        return () => {
            clearTimeout(showRightAnswerTimer.current);
        };
    }, []);

    const answers = useMemo(() => {
        return round.answers.map((value, key) => {
            const answerId = key + 1;
            const isCorrect = Boolean(selectedAnswer && rightAnswer === answerId);

            return {
                isSelected: !isCorrect && selectedAnswer === answerId,
                isCorrect,
                text: value,
                onClick: value && handleAnswerClick.bind(null, user.id, answerId),
            };
        });
    }, [handleAnswerClick, rightAnswer, round.answers, selectedAnswer, user.id]);

    return (
        <QuestionAnswersUI
            question={round.question}
            answers={answers}
            progressLeft={progressLeft}
            isAnswerClickDisabled={selectedAnswer !== undefined}
            authorName={getAuthorName(round)}
            hasRateQuestion={user.level >= MIN_LEVEL_TO_RATE_QUESTION}
            onQuestionLikeClick={handleQuestionLikeClick}
            onQuestionDislikeClick={handleQuestionDislikeClick}
            onSendQuestionProblem={
                !problemSent && user.isAllowModerate ? handleSendQuestionProblem : undefined
            }
        />
    );
};

QuestionPanel.propTypes = {};

export default QuestionPanel;
