import axios from 'axios';
import { SERVER_API } from '../../config';

export function addQuestion({ question, answer1, answer2, answer3, answer4, level, rightAnswer }) {
    return function (_dispatch, getState) {
        return axios.put(
            `${SERVER_API}/question`,
            {
                question,
                answer1,
                answer2,
                answer3,
                answer4,
                level,
                rightAnswer,
                session: getState().app.session,
            },
            { withCredentials: true }
        );
    };
}

export function sendQuestionEmotion(questionId, emotion) {
    return function (_dispatch, getState) {
        return axios.post(
            `${SERVER_API}/question/emotion`,
            {
                questionId,
                emotion,
                session: getState().app.session,
            },
            { withCredentials: true }
        );
    };
}

export function getNewQuestionsCount() {
    return function (dispatch, getState) {
        return axios
            .get(`${SERVER_API}/question/new-count?session=${getState().app.session}`, {
                withCredentials: true,
            })
            .then((response) => {
                dispatch(setNewQuestionsCount(response.data.count));
            });
    };
}

export function getNewQuestion() {
    return function (dispatch, getState) {
        dispatch(setIsLoading(true));

        return axios
            .get(`${SERVER_API}/question/new?session=${getState().app.session}`, {
                withCredentials: true,
            })
            .then((response) => {
                dispatch(setWorkingQuestion(response.data.question));
                dispatch(setIsLoading(false));
            });
    };
}

let questionInProgress = false;

export function getUserQuestions(type = 'new', offset, mode) {
    return function (dispatch, getState) {
        if (questionInProgress) {
            return;
        }

        questionInProgress = true;
        dispatch(setIsLoading(true));

        if (offset === 0) {
            dispatch(setUserQuestions([]));
        }

        return axios
            .get(
                `${SERVER_API}/question/all?session=${
                    getState().app.session
                }&offset=${offset}&mode=${mode}`,
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (type === 'append') {
                    dispatch(appendUserQuestions(response.data.questions));
                } else {
                    dispatch(setUserQuestions(response.data.questions));
                }

                dispatch(setIsLoading(false));
                questionInProgress = false;
            });
    };
}

export function updateModeratedQuestion({
    questionId,
    question,
    answer1,
    answer2,
    answer3,
    answer4,
    level,
    rightAnswer,
    valid,
}) {
    return function (dispatch, getState) {
        dispatch(setIsLoading(true));

        return axios
            .post(
                `${SERVER_API}/question/new`,
                {
                    questionId,
                    question,
                    answer1,
                    answer2,
                    answer3,
                    answer4,
                    level,
                    rightAnswer,
                    valid,
                    session: getState().app.session,
                },
                { withCredentials: true }
            )
            .then(
                () => {
                    dispatch(setIsLoading(false));
                },
                (error) => {
                    dispatch(setIsLoading(false));
                    throw new Error(error);
                }
            );
    };
}

const SET_NEW_QUESTIONS_COUNT = Symbol('SET_NEW_QUESTIONS_COUNT');
function setNewQuestionsCount(newQuestionsCount = 0) {
    return {
        type: SET_NEW_QUESTIONS_COUNT,
        newQuestionsCount,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');
function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

const SET_WORKING_QUESTION = Symbol('SET_WORKING_QUESTION');
export function setWorkingQuestion(workingQuestion = false) {
    return {
        type: SET_WORKING_QUESTION,
        workingQuestion,
    };
}

const SET_USER_QUESTIONS = Symbol('SET_USER_QUESTIONS');
function setUserQuestions(questions = []) {
    return {
        type: SET_USER_QUESTIONS,
        questions,
    };
}

const APPEND_USER_QUESTIONS = Symbol('APPEND_USER_QUESTIONS');
function appendUserQuestions(questions = []) {
    return {
        type: APPEND_USER_QUESTIONS,
        questions,
    };
}

const initialState = {
    newQuestionsCount: 0,
    workingQuestion: null,
    isLoading: false,
    questions: [],
    isLastBlock: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_NEW_QUESTIONS_COUNT] = function () {
        return {
            ...state,
            newQuestionsCount: action.newQuestionsCount,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    exec[SET_WORKING_QUESTION] = function () {
        return {
            ...state,
            workingQuestion: action.workingQuestion,
        };
    };

    exec[SET_USER_QUESTIONS] = function () {
        return {
            ...state,
            questions: action.questions,
        };
    };

    exec[APPEND_USER_QUESTIONS] = function () {
        return {
            ...state,
            questions: [...state.questions, ...action.questions],
            isLastBlock: action.questions.length === 0,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
