import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../../../UI/Button';
import UserInfoUI from '../../../UI/UserInfo';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';

const fixFirstLetter = (str) => {
    const newStr = str.toLowerCase();
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

const MAX_WIDTH = 400;
const MAX_HEIGHT = 400;
const IMAGE_TYPE = 'image/jpeg';
const IMAGE_QUALITY = 0.9;

const resizeFile = async (preview) => {
    const img = document.createElement('img');
    img.src = preview;

    await new Promise((resolve) => (img.onload = resolve));

    // Create your canvas
    const canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    let width = img.naturalWidth;
    let height = img.naturalHeight;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }

    canvas.width = width;
    canvas.height = height;
    ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    URL.revokeObjectURL(preview);

    const preparedData = canvas.toDataURL(IMAGE_TYPE, IMAGE_QUALITY);

    return preparedData;
};

function UpdateUserDetailsUI({
    isLoading,
    onCancelClick,
    onContinueClick,
    isRequired = false,
    profile,
    isProfileUpdated,
}) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-update-profile': true,
    });

    const [firstName, setFirstName] = useState(profile.firstName);
    const [lastName, setLastName] = useState(profile.lastName);
    const [photo, setPhoto] = useState(profile.photoUrl);
    const [error, setError] = useState('');

    const firstNameCallback = useCallback((event) => {
        setError('');
        setFirstName(fixFirstLetter(event.target.value));
    }, []);

    const lastNameCallback = useCallback((event) => {
        setError('');
        setLastName(fixFirstLetter(event.target.value));
    }, []);

    const readyCallback = useCallback(() => {
        if (firstName && lastName && photo) {
            onContinueClick({
                firstName,
                lastName,
                photo,
            });
        } else {
            setError('Заполните все поля');
        }
    }, [firstName, lastName, photo, onContinueClick]);

    const handlePhotoSelect = useCallback((event) => {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];

            const reader = new FileReader();
            reader.onload = (fileEvent) => {
                if (fileEvent.target && fileEvent.target.result) {
                    resizeFile(fileEvent.target.result).then((result) => {
                        setPhoto(result);
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const renderForm = () => {
        return (
            <>
                {isRequired ? (
                    <p>Для начала игры, пожалуйста, загрузите фото и укажите ваше имя в игре.</p>
                ) : null}

                <div className="error">{error ? <span>{error}</span> : null}</div>
                <div className="user-details">
                    <div className="photo-wrapper">
                        <UserInfoUI photoUrl={photo} />

                        <span className="photo-uploader">
                            <input type="file" onChange={handlePhotoSelect} />
                            Загрузить фото
                        </span>
                    </div>

                    <div className="form-inputs">
                        <div className="input">
                            <input
                                type="text"
                                onChange={firstNameCallback}
                                value={firstName}
                                placeholder="Ваше имя"
                                maxLength={20}
                                tabIndex={1}
                            />
                        </div>
                        <div className="input">
                            <input
                                type="text"
                                onChange={lastNameCallback}
                                value={lastName}
                                placeholder="Ваша фамилия"
                                maxLength={20}
                                tabIndex={2}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderSuccess = () => {
        return (
            <div className="done">
                <div className="success">
                    <i className="icon">
                        <FontAwesomeIcon icon={faSmile} mask={['far', 'circle']} />
                    </i>
                </div>
                <div className="title">Профиль обновлен</div>
                <div className="sub-title">Данные вашего профиля были успешно обновлены</div>
            </div>
        );
    };

    return (
        <div className={classes}>
            <h2>{isRequired ? `Ваш профиль игрока` : 'Изменение профиля'}</h2>
            <div className="content">{!isProfileUpdated ? renderForm() : renderSuccess()}</div>
            <div className={'buttons'}>
                <Button text={'Сохранить'} onClick={readyCallback} isLoading={isLoading} />
                <div className="sub-button" onClick={onCancelClick}>
                    Отмена
                </div>
            </div>
        </div>
    );
}

UpdateUserDetailsUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isRequired: PropTypes.bool,
    profile: PropTypes.object,
    isProfileUpdated: PropTypes.bool,
};

export default UpdateUserDetailsUI;
