import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DialogAddQuestionUI from '../../../../components/Dialog/Types/AddQuestion';
import { hideDialog, showDialog } from '../../../../store/dialog';
import {
    getNewQuestion,
    updateModeratedQuestion,
    getNewQuestionsCount,
    setWorkingQuestion,
} from '../../../../store/question';
import { DIALOG_SEE_OWN_QUESTIONS } from '../../Types/SeeOwnQuestions/type';

export const DIALOG_MANAGE_QUESTION = 'manage-question';

class DialogAddQuestion extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        if (!this.props.workingQuestion) {
            this.props.actions.getNewQuestion();
        }
    }

    render() {
        return (
            <DialogAddQuestionUI
                onContinueClick={this.handleContinueClick}
                onCancelClick={this.handleCancelClick}
                isLoading={this.props.isLoading}
                workingQuestion={this.props.workingQuestion}
                isManagement={true}
            />
        );
    }

    handleContinueClick(
        { question, answer1, answer2, answer3, answer4, level, rightAnswer },
        isValid
    ) {
        if (this.props.isLoading) {
            return false;
        }

        this.props.actions
            .updateModeratedQuestion({
                questionId: this.props.workingQuestion.id,
                question,
                answer1,
                answer2,
                answer3,
                answer4,
                level,
                rightAnswer,
                valid: isValid ? 'Y' : 'N',
            })
            .then(() => {
                if (this.props.ownProfile.type === 'admin') {
                    const newQuestionValue = this.props.newQuestionsCount - 1;
                    if (newQuestionValue > 0) {
                        this.props.actions.getNewQuestion();
                    }
                    this.props.actions.getNewQuestionsCount();
                } else {
                    this.props.actions.hideDialog();

                    setTimeout(() => {
                        this.props.actions.setWorkingQuestion(undefined);
                        this.props.actions.showDialog(DIALOG_SEE_OWN_QUESTIONS);
                    }, 100);
                }
            })
            .catch((result) => {
                const message = (result && result.error) || 'Ошибка сохранения вопроса';
                // eslint-disable-next-line no-alert
                alert(message);
            });
    }

    handleCancelClick() {
        this.props.actions.hideDialog();

        if (this.props.ownProfile.type !== 'admin') {
            setTimeout(() => {
                this.props.actions.setWorkingQuestion(undefined);
                this.props.actions.showDialog(DIALOG_SEE_OWN_QUESTIONS);
            }, 100);
        }
    }
}

const mapStateToProps = function (state) {
    return {
        appType: state.app.appType,
        workingQuestion: state.question.workingQuestion,
        isLoading: state.question.isLoading,
        newQuestionsCount: state.question.newQuestionsCount,
        ownProfile: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                getNewQuestion,
                getNewQuestionsCount,
                updateModeratedQuestion,
                setWorkingQuestion,
                hideDialog,
                showDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddQuestion);
