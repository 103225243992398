/* eslint-disable */
import axios from 'axios';
import {
    SERVER_API,
    SESSION_STORAGE_KEY,
    ADV_INTERSTITIAL,
    ADV_PRELOADER,
    ADV_REWARD,
} from '../../config';

import { setUserInfo, fetchLastProfileGames, fetchProfile } from '../profile';
import UserModel from '../../model/user';
import { VK_GAME_URL, GAME_SHARE_URL, GROUP_VK_ID, VK_APP_ID } from '../../config/social';
import { push } from 'connected-react-router';
import { setIsGameStarted } from '../game';
import Storage from '../../utils/storage';
import { showNotification } from '../notification';
import NotificationModel from '../../model/notification';
import { fetchTopUsers } from '../top';
import i18n from '../../i18n';

import {
    sendReachGoal,
    EVENT_START_CLICK_INVITE,
    EVENT_START_SENT_INVITE_SUCCESS,
    EVENT_ADV_FOR_MONEY,
    EVENT_SEE_ADV_INTERSTITIAL,
    EVENT_SEE_ADV_PRELOAD,
} from '../statistic';
import { getVkBridge } from '../../utils/vkBridge';
let navigate;

//if (process.env.REACT_APP_BROWSER) {

//} else {
// navigate = require('../../utils/navigationService');
//}

export const TYPE_VK = 'vk';
export const TYPE_VK_APP = 'vk-app';
export const TYPE_OK = 'ok';
export const TYPE_FB = 'fb';
export const TYPE_WS = 'website';
export const TYPE_NATIVE = 'native';
export const TYPE_YANDEX = 'yandex';

export const STATE_INACTIVE = 'inactive';
export const STATE_ACTIVE = 'active';
export const STATE_BACKGROUND = 'background';

const SET_IS_READY = Symbol('SET_IS_READY');

export function setIsReady(isReady = false) {
    return {
        type: SET_IS_READY,
        isReady,
    };
}

const SET_SESSION = Symbol('SET_SESSION');

export function setSession(session = null) {
    return {
        type: SET_SESSION,
        session,
    };
}

const SET_SOCKET = Symbol('SET_SOCKET');

export function setSocket(socket = null) {
    return {
        type: SET_SOCKET,
        socket,
    };
}

const SET_APP_TYPE = Symbol('SET_APP_TYPE');

export function setAppType(appType = null) {
    return {
        type: SET_APP_TYPE,
        appType,
    };
}

const SET_APP_STATE = Symbol('SET_APP_STATE');

export function setAppState(appState = null) {
    return {
        type: SET_APP_STATE,
        appState,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

const SET_USER_SHOULD_PASS_AUTH = Symbol('SET_USER_SHOULD_PASS_AUTH');

export function setShouldPassAuth(userShouldPassAuth = true) {
    return {
        type: SET_USER_SHOULD_PASS_AUTH,
        userShouldPassAuth,
    };
}

const SET_ONLINE_USERS_COUNT = Symbol('SET_ONLINE_USERS_COUNT');

export function setOnlineUsersCount(onlineUsersCount = 0) {
    return {
        type: SET_ONLINE_USERS_COUNT,
        onlineUsersCount,
    };
}

const SET_ALLOW_BUY_MONEY = Symbol('SET_ALLOW_BUY_MONEY');
export function setAllowBuyMoney(isBuyMoneyAllowed = true) {
    return {
        type: SET_ALLOW_BUY_MONEY,
        isBuyMoneyAllowed,
    };
}

const SET_ALLOW_SEE_ADV = Symbol('SET_ALLOW_SEE_ADV');
export function setAllowSeeAdv(isAllowSeeAdv = true) {
    return {
        type: SET_ALLOW_SEE_ADV,
        isAllowSeeAdv,
    };
}

export function userAuthorization(session, user) {
    return function (dispatch) {
        dispatch(setSession(session));
        dispatch(setUserInfo(user));
        dispatch(fetchLastProfileGames());
        dispatch(fetchTopUsers());
    };
}

export function authVkGame(externalUser, accessToken) {
    return function (dispatch) {
        return axios
            .post(`${SERVER_API}/login-app/vk`, {
                accessToken,
                externalUser,
            })
            .then(function (response) {
                if (response.data.session) {
                    const user = new UserModel(response.data.user);
                    dispatch(userAuthorization(response.data.session, user));
                }
            });
    };
}

export function authVkApp(externalUser, queryParams = {}) {
    return function (dispatch) {
        return axios
            .post(`${SERVER_API}/login-app/vk-app`, {
                externalUser,
                queryParams,
            })
            .then(
                function (response) {
                    if (response.data.session) {
                        const user = new UserModel(response.data.user);
                        dispatch(userAuthorization(response.data.session, user));

                        if (response.data.events) {
                            dispatch(processServerEvents(response.data.events));
                        }
                    }
                },
                () => {}
            );
    };
}

export function authYandexGame(externalUser) {
    return function (dispatch) {
        return axios
            .post(`${SERVER_API}/login-app/yandex`, {
                externalUser,
            })
            .then(function (response) {
                if (response.data.session) {
                    const user = new UserModel(response.data.user);
                    dispatch(userAuthorization(response.data.session, user));

                    if (response.data.events) {
                        dispatch(processServerEvents(response.data.events));
                    }
                }
            });
    };
}

export function authFbApp(externalUser, accessToken) {
    return function (dispatch) {
        return axios
            .post(`${SERVER_API}/login-app/fb`, {
                externalUser,
                accessToken,
            })
            .then(function (response) {
                if (response.data.session) {
                    const user = new UserModel(response.data.user);
                    dispatch(userAuthorization(response.data.session, user));
                }
            });
    };
}

export function auth(session = null, appType = null) {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        return axios
            .post(
                `${SERVER_API}/auth`,
                {
                    session,
                    appType,
                },
                { withCredentials: true }
            )
            .then((response) => {
                dispatch(setIsLoading(false));

                if (response.data.session) {
                    // User authorized
                    Storage.setItem(SESSION_STORAGE_KEY, response.data.session).then(() => {
                        const user = new UserModel(response.data.user);
                        dispatch(userAuthorization(response.data.session, user));
                    });
                    return Promise.resolve(response.data.session);
                } else {
                    return Promise.reject(new Error('Error'));
                }
            })
            .catch(() => {
                dispatch(setIsLoading(false));
                return Promise.reject(new Error('Error'));
            });
    };
}

export function logout(onDone = () => {}) {
    return function (dispatch, getState) {
        const state = getState();

        dispatch(setIsGameStarted());
        // Show auth screen
        dispatch(setShouldPassAuth(true));
        // Disconnect socket
        dispatch(setSession(null));
        dispatch(setIsReady(false));

        // Clear storage session
        Storage.remove([SESSION_STORAGE_KEY]);

        return axios
            .post(
                `${SERVER_API}/logout`,
                {
                    session: state.app.session,
                    appType: state.app.appType,
                },
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data.result) {
                    onDone();
                }
            });
    };
}

export function showInvite(appType) {
    return function (dispatch) {
        dispatch(
            sendReachGoal(EVENT_START_CLICK_INVITE, {
                appType,
            })
        );

        switch (appType) {
            case TYPE_VK:
                window.VK.callMethod('showInviteBox');
                break;
            case TYPE_WS:
            case TYPE_YANDEX:
                if (navigator.share) {
                    navigator
                        .share({
                            title: i18n.t('inviteSubject'),
                            text: i18n.t('inviteBodyShort'),
                            url: GAME_SHARE_URL,
                        })
                        .then(() => {
                            console.log('Thanks for sharing!');
                        })
                        .catch(console.error);
                } else {
                    window.open(
                        `mailto:?subject=${i18n.t('inviteSubject')}&body=${i18n.t('inviteBody')}`
                    );
                }

                break;
            case TYPE_FB:
                window.FB.ui(
                    {
                        method: 'apprequests',
                        message: 'Хочу сыграть с тобой в игру Умник! Жду тебя в игре.',
                    },
                    function (response) {
                        console.log(response);
                    }
                );
                break;
            case TYPE_VK_APP:
                getVkBridge()
                    .sendPromise('VKWebAppShowInviteBox')
                    .then(() => {
                        dispatch(
                            sendReachGoal(EVENT_START_SENT_INVITE_SUCCESS, {
                                appType,
                            })
                        );
                    })
                    .catch(() => {});
                break;
            default:
        }
    };
}

export function showShareBox(appType) {
    return function () {
        switch (appType) {
            case TYPE_VK:
                window.VK.api('wall.post', {
                    message: i18n.t('inviteWallPost'),
                    attachments: `${VK_GAME_URL}`,
                });
                break;
            case TYPE_VK_APP:
                getVkBridge().sendPromise('VKWebAppShowWallPostBox', {
                    message: i18n.t('inviteWallPost'),
                    attachments: `${VK_GAME_URL}`,
                });
                break;
            case TYPE_FB:
                window.FB.ui(
                    {
                        method: 'share',
                        href: GAME_SHARE_URL,
                    },
                    function () {}
                );
                break;
            default:
        }
    };
}

export function submitYandexPayment(signature, onDone) {
    return function (dispatch, getState) {
        const session = getState().app.session;

        return axios
            .post(
                `${SERVER_API}/money/yandex-callback`,
                {
                    signature,
                    session,
                },
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data.response) {
                    dispatch(fetchProfile());

                    dispatch(
                        showNotification(
                            new NotificationModel({
                                title: 'Успешная покупка',
                                description: 'Монеты добавлены',
                                timeout: 3000,
                            })
                        )
                    );

                    if (onDone) {
                        onDone(response.data.response);
                    }
                }
            });
    };
}

export function showOrderBox(appType, item, params) {
    return function (dispatch) {
        switch (appType) {
            case TYPE_VK:
                window.VK.callMethod('showOrderBox', {
                    type: 'item',
                    item: item.type,
                });
                break;
            case TYPE_VK_APP:
                getVkBridge()
                    .sendPromise('VKWebAppShowOrderBox', {
                        type: 'item',
                        item: item.type,
                    })
                    .then(() => {
                        dispatch(
                            showNotification(
                                new NotificationModel({
                                    title: 'Успешная покупка',
                                    description: 'Монеты добавлены',
                                    timeout: 3000,
                                })
                            )
                        );
                        setTimeout(() => {
                            dispatch(fetchProfile());
                        }, 1000);
                    })
                    .catch(() => {});
                break;
            case TYPE_YANDEX:
                params.yandexPayments
                    .purchase({ id: item.type, developerPayload: JSON.stringify(item) })
                    .then((purchase) => {
                        dispatch(
                            submitYandexPayment(purchase.signature, () => {
                                params.yandexPayments.consumePurchase(purchase.purchaseToken);
                            })
                        );
                    });
                break;
            default:
        }
    };
}

export function sendGameRequest(appType, user) {
    return function () {
        switch (appType) {
            case TYPE_VK:
                window.VK.callMethod('showRequestBox', {
                    user_id: user.externalId,
                    message: i18n.t('userRequestVk', { url: VK_GAME_URL, name: user.firstName }),
                });
                break;
            case TYPE_VK_APP:
                getVkBridge()
                    .sendPromise('VKWebAppShowRequestBox', {
                        uid: user.externalId,
                        message: i18n.t('userRequestVk', {
                            url: VK_GAME_URL,
                            name: user.firstName,
                        }),
                    })
                    .then(() => {})
                    .catch(() => {});
                break;
            default:
        }
    };
}

export function processServerEvents(events) {
    return function (dispatch, getState) {
        const appType = getState().app.appType;
        const session = getState().app.session;

        const event = events[0];
        // At this moment we can process only one event per time

        return axios
            .post(
                `${SERVER_API}/notification/confirm`,
                {
                    eventType: event,
                    session,
                },
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data.result) {
                    switch (event) {
                        case 'allow_add_favorites':
                            processAddToFavorites(dispatch, appType, session);
                            break;
                        case 'subscribe_to_notifications':
                            processSubscribeToNotifications(dispatch, appType, session);
                            break;
                        case 'join_group':
                            processJoinGroup(dispatch, appType, session);
                            break;
                        default:
                    }
                }
            });
    };
}

function sendUpdate(type, session) {
    return axios.post(
        `${SERVER_API}/notification/update`,
        {
            eventType: type,
            session,
        },
        { withCredentials: true }
    );
}

function processAddToFavorites(dispatch, appType, session) {
    switch (appType) {
        case TYPE_VK_APP:
            getVkBridge()
                .sendPromise('VKWebAppAddToFavorites', {})
                .then((data) => {
                    if (data.result) {
                        sendUpdate('allow_add_favorites', session);
                    }
                })
                .catch(() => {});
            break;
        default:
    }
}

function processSubscribeToNotifications(dispatch, appType, session) {
    switch (appType) {
        case TYPE_VK_APP:
            getVkBridge()
                .sendPromise('VKWebAppAllowNotifications', {})
                .then((data) => {
                    if (data.result) {
                        sendUpdate('subscribe_to_notifications', session);
                    }
                })
                .catch(() => {});
            break;
        default:
    }
}

function processJoinGroup(dispatch, appType, session) {
    switch (appType) {
        case TYPE_VK_APP:
            getVkBridge()
                .sendPromise('VKWebAppJoinGroup', { group_id: GROUP_VK_ID })
                .then((data) => {
                    if (data.result) {
                        sendUpdate('join_group', session);
                    }
                })
                .catch(() => {});
            break;
        default:
    }
}

export function goToPage(page, options = {}) {
    return function (dispatch, getState) {
        if (getState().app.appType === TYPE_NATIVE) {
            navigate(page, options);
        } else {
            dispatch(push(page, options));
        }
    };
}

export function sendAppPushToken(token, deviceId) {
    return function (dispatch, getState) {
        const userId = getState().profile.info.id;
        const session = getState().app.session;

        return axios.post(
            `${SERVER_API}/user/token`,
            {
                token,
                userId,
                session,
                deviceId,
            },
            { withCredentials: true }
        );
    };
}

export function showAdv(type, userId = null) {
    return function (dispatch, getState) {
        const appType = getState().app.appType;
        let webVkType;

        return new Promise((resolve, reject) => {
            let statistic;

            switch (type) {
                case ADV_REWARD:
                    statistic = EVENT_ADV_FOR_MONEY;
                    webVkType = 'rewarded';
                    break;
                case ADV_INTERSTITIAL:
                    statistic = EVENT_SEE_ADV_INTERSTITIAL;
                    break;
                case ADV_PRELOADER:
                    statistic = EVENT_SEE_ADV_PRELOAD;
                    webVkType = 'preloader';
                    break;
                default:
                    reject(new Error('Incorrect ADV type'));
                    return;
            }

            switch (appType) {
                case TYPE_YANDEX:
                    if (window.YaGames) {
                        if (type === ADV_PRELOADER) {
                            window.YaGames.init()
                                .then((ysdk) => {
                                    ysdk.adv.showFullscreenAdv();
                                    resolve();
                                })
                                .catch(() => {
                                    reject();
                                });
                        } else if (type === ADV_INTERSTITIAL) {
                            window.YaGames.init()
                                .then((ysdk) => {
                                    ysdk.adv.showFullscreenAdv();
                                    resolve();
                                })
                                .catch(() => {
                                    reject();
                                });
                        } else if (type === ADV_REWARD) {
                            window.YaGames.init()
                                .then((ysdk) => {
                                    ysdk.adv.showRewardedVideo({
                                        callbacks: {
                                            onRewarded: () => {
                                                sendReachGoal(statistic, {
                                                    result: true,
                                                });
                                                resolve();
                                            },
                                            onClose: () => {
                                                reject();
                                            },
                                            onError: () => {
                                                reject();
                                            },
                                        },
                                    });
                                })
                                .catch(() => {
                                    reject();
                                });
                        } else {
                            reject(new Error(`Unknown event`));
                        }
                    } else {
                        reject(new Error(`window.YaGames`));
                    }
                    break;
                case TYPE_VK_APP:
                    getVkBridge()
                        .sendPromise('VKWebAppShowNativeAds', { ad_format: type })
                        .then((data) => {
                            dispatch(
                                sendReachGoal(statistic, {
                                    result: data.result,
                                })
                            );

                            resolve();
                        })
                        .catch((error) => {
                            reject(new Error(`Error from VK bridge - ${JSON.stringify(error)}`));
                        });
                    break;
                case TYPE_VK:
                    if (!window.admanInit) {
                        reject(new Error(`No admanInit`));
                    } else {
                        if (webVkType && VK_APP_ID && userId) {
                            const params = {
                                user_id: Number(userId),
                                app_id: VK_APP_ID,
                                // params: {preview: 1}, // для проверки корректности работы рекламы
                                type: webVkType,
                            };
                            window.admanInit(
                                params,
                                (adman) => {
                                    adman.onStarted(function () {
                                        sendReachGoal(statistic, {
                                            result: true,
                                        });
                                    });
                                    adman.onCompleted(function () {});
                                    adman.onSkipped(function () {});
                                    adman.onClicked(function () {});
                                    adman.start('preroll');
                                    resolve();
                                },
                                () => {
                                    reject(new Error(`No adv`));
                                }
                            );
                        } else {
                            reject(new Error(`No required parameters`));
                        }
                    }
                    break;
                default:
                    reject(new Error(`No available appType`));
            }
        });
    };
}

const initialState = {
    isReady: false,
    session: null,
    appType: null,
    isLoading: false,
    userShouldPassAuth: false,
    appState: STATE_ACTIVE,
    onlineUsersCount: 0,
    isBuyMoneyAllowed: false,
    isAllowSeeAdv: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_IS_READY] = function () {
        return {
            ...state,
            isReady: action.isReady,
        };
    };

    exec[SET_SESSION] = function () {
        return {
            ...state,
            session: action.session,
        };
    };

    exec[SET_SOCKET] = function () {
        return {
            ...state,
            socket: action.socket,
        };
    };

    exec[SET_APP_TYPE] = function () {
        return {
            ...state,
            appType: action.appType,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    exec[SET_USER_SHOULD_PASS_AUTH] = function () {
        return {
            ...state,
            userShouldPassAuth: action.userShouldPassAuth,
        };
    };

    exec[SET_APP_STATE] = function () {
        return {
            ...state,
            appState: action.appState,
        };
    };

    exec[SET_ONLINE_USERS_COUNT] = function () {
        return {
            ...state,
            onlineUsersCount: action.onlineUsersCount,
        };
    };

    exec[SET_ALLOW_BUY_MONEY] = function () {
        return {
            ...state,
            isBuyMoneyAllowed: action.isBuyMoneyAllowed,
        };
    };

    exec[SET_ALLOW_SEE_ADV] = function () {
        return {
            ...state,
            isAllowSeeAdv: action.isAllowSeeAdv,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call(null);
    }

    return state;
}
