import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import i18n from '../../../../i18n';
import ProgressBarUI from '../../../UI/ProgressBar';
import {
    faUser,
    faThumbsUp,
    faThumbsDown,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const renderAnswer = (answer, key, isAnswerClickDisabled) => {
    const { isSelected, isCorrect } = answer;

    const answerClasses = classnames({
        'answer-wrapper': true,
        'is-selected': isSelected,
        'is-correct': isCorrect,
        'is-disabled': isAnswerClickDisabled || !answer.onClick,
        'is-active': !isAnswerClickDisabled && answer.onClick,
    });

    return (
        <div
            className={answerClasses}
            key={key}
            onClick={isAnswerClickDisabled || !answer.onClick ? undefined : answer.onClick}
        >
            <div className="answer">
                <span className="letter">{i18n.t(`answersLetters.${key}`)}</span>
                <span className="text">{answer.text}</span>
            </div>
        </div>
    );
};

const QuestionAnswersUI = ({
    question,
    answers,
    progressLeft,
    isAnswerClickDisabled,
    authorName,
    hasRateQuestion,
    onQuestionLikeClick,
    onQuestionDislikeClick,
    onSendQuestionProblem,
}) => {
    return (
        <div id="questions-answers">
            <div className="question-wrapper">
                <div className="question">
                    {authorName ? (
                        <div className="author-name">
                            <FontAwesomeIcon icon={faUser} />
                            {authorName}
                        </div>
                    ) : null}
                    {hasRateQuestion ? (
                        <div className="rate-emotions">
                            <div className="like" onClick={onQuestionLikeClick}>
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </div>
                            <div className="dislike" onClick={onQuestionDislikeClick}>
                                <FontAwesomeIcon icon={faThumbsDown} />
                            </div>
                        </div>
                    ) : null}
                    {onSendQuestionProblem ? (
                        <div className="question-problem" onClick={onSendQuestionProblem}>
                            <i data-tip data-for="problem" className="icon">
                                <FontAwesomeIcon icon={faExclamationCircle} />
                            </i>
                            <ReactTooltip id="problem" place="top" type="light" effect="solid">
                                Сообщить об ошибке
                            </ReactTooltip>
                        </div>
                    ) : null}
                    <span>{question}</span>
                </div>
                <div className="progress-block">
                    <ProgressBarUI progress={progressLeft} />
                </div>
            </div>

            <div className="answers">
                {answers.map((answer, answerKey) =>
                    renderAnswer(answer, answerKey + 1, isAnswerClickDisabled)
                )}
            </div>
        </div>
    );
};

QuestionAnswersUI.propTypes = {
    isAnswerClickDisabled: PropTypes.bool.isRequired,
    progressLeft: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            isSelected: PropTypes.bool.isRequired,
            isCorrect: PropTypes.bool.isRequired,
            text: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
    authorName: PropTypes.string,
    hasRateQuestion: PropTypes.bool,
    onQuestionLikeClick: PropTypes.func,
    onQuestionDislikeClick: PropTypes.func,
    onSendQuestionProblem: PropTypes.func,
};

export default QuestionAnswersUI;
