import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { numberWithSpaces } from '../../../utils';
import i18n from '../../../i18n';

function LevelProgressUI(props) {
    let progress = 0;

    if (props.levelNeedPoints + props.levelPoints > 0) {
        progress = Math.floor(
            (props.levelPoints * 100) / (props.levelNeedPoints + props.levelPoints)
        );
    }

    return (
        <div className="user-level-progress">
            <div className="total">
                <div className="progress" style={{ width: `${progress}%` }}></div>
                <div className="points">{Math.round(progress)}%</div>
                <div className={'info-tooltip'}>
                    <i data-tip data-for="progress-tooltip" className="icon">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </i>
                    <ReactTooltip id="progress-tooltip" place="bottom" type="light" effect="solid">
                        <span className={'tooltip'}>
                            Прогресс до следующего уровня. <br />
                            Осталось{' '}
                            {numberWithSpaces(
                                props.levelNeedPoints + props.levelPoints - props.levelPoints
                            )}{' '}
                            очков
                        </span>
                    </ReactTooltip>
                </div>
            </div>
            {props.totalPoints !== undefined ? (
                <div className="total-points">
                    <i className="icon">
                        <FontAwesomeIcon icon={faStar} />
                    </i>
                    <span>
                        {i18n.t('totalPoints', { points: numberWithSpaces(props.totalPoints) })}
                    </span>
                </div>
            ) : null}
        </div>
    );
}

LevelProgressUI.propTypes = {
    levelPoints: PropTypes.number.isRequired,
    levelNeedPoints: PropTypes.number.isRequired,
    totalPoints: PropTypes.number,
};

export default LevelProgressUI;
