import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '../../../Loading';
import Button from '../../../UI/Button';
import './style.scss';

const Question = function ({ question, message, onEditClick, onSaveMessageClick, onDeleteClick }) {
    const [inputMessage, setInputMessage] = useState(message);
    const [saved, setSaved] = useState(false);

    const timer = useRef();

    const handleChangeMessage = useCallback((event) => {
        setInputMessage(event.target.value);
    }, []);

    useEffect(() => {
        if (saved) {
            timer.current = window.setTimeout(() => {
                setSaved(false);
            }, 2000);
        }

        return () => {
            window.clearTimeout(timer.current);
        };
    }, [saved]);

    return (
        <div className="question">
            <div className="title-block">
                <div className="sub-info">
                    <span>№ {question.id}</span>
                    {saved ? <span className="saved">Сохранено</span> : null}
                </div>
                <div>
                    <div className="title">{question.question}</div>
                    <div className="answers">
                        <span className="answer">A: {question.answer_1}</span>
                        <span className="answer">Б: {question.answer_2}</span>
                        <span className="answer">В: {question.answer_3}</span>
                        <span className="answer">Г: {question.answer_4}</span>
                    </div>
                </div>
                <div className="message">
                    <textarea
                        onChange={handleChangeMessage}
                        placeholder="Опишите ошибку в вопросе или ответах"
                        maxLength={255}
                        className={''}
                    >
                        {inputMessage}
                    </textarea>
                </div>
            </div>
            <div className="action">
                <Button
                    className={'smaller-inline'}
                    text={'Сохранить'}
                    onClick={() => {
                        onSaveMessageClick(question.id, inputMessage);
                        setSaved(true);
                    }}
                />
                <Button
                    className={'smaller-inline decline'}
                    text={'Удалить'}
                    onClick={() => {
                        onDeleteClick(question.id);
                    }}
                />
                {onEditClick ? (
                    <Button
                        className={'smaller-inline edit'}
                        text={'Редактировать вопрос'}
                        onClick={() => {
                            onEditClick(question.id);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};

function DialogSeeProblemQuestionsUI({
    isLoading,
    isAppendLoading,
    onCancelClick,
    questions,
    onSaveMessageClick,
    onDeleteClick,
    onEditClick,
    onScroll,
}) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-problem-questions': true,
    });

    const questionList = useMemo(() => {
        return questions.map((entry) => {
            const question = entry.question;

            return (
                <Question
                    key={question.id}
                    question={question}
                    isAdmin={false}
                    onSaveMessageClick={onSaveMessageClick}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    message={entry.message}
                />
            );
        });
    }, [questions, onSaveMessageClick, onDeleteClick, onEditClick]);

    return (
        <div className={classes}>
            <h2>Отмеченные вопросы с ошибками</h2>

            <div className="content">
                {isLoading ? (
                    <div>Загрузка вопросов...</div>
                ) : (
                    <div className={'questions'} onScroll={onScroll}>
                        {questions.length ? questionList : null}
                    </div>
                )}
            </div>

            <div className={'buttons'}>
                {isAppendLoading ? <Loading color={'#ccc'} /> : null}
                <div className="sub-button" onClick={onCancelClick}>
                    Закрыть
                </div>
            </div>
        </div>
    );
}

DialogSeeProblemQuestionsUI.propTypes = {
    isLoading: PropTypes.bool,
    isAppendLoading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onSaveMessageClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onScroll: PropTypes.func,
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.shape({
                id: PropTypes.number,
                question: PropTypes.string,
                is_active: PropTypes.string,
                is_checked: PropTypes.string,
                answer_1: PropTypes.string,
                answer_2: PropTypes.string,
                answer_3: PropTypes.string,
                answer_4: PropTypes.string,
                right_answer: PropTypes.number,
                from_user_id: PropTypes.number,
                likes: PropTypes.number,
                dislikes: PropTypes.number,
            }),
            message: PropTypes.string,
        })
    ),
};

export default DialogSeeProblemQuestionsUI;
