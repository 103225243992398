import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';

function ProgressBarUI({ progress, text }) {
    const classes = classnames({
        progress: true,
        warn: progress > 51 && progress <= 75,
        danger: progress > 15 && progress <= 50,
        critical: progress <= 15,
    });

    return (
        <div className="progress-bar">
            <div className={classes} style={{ width: `${progress}%` }}></div>
            {text ? <div className="text">{text}</div> : null}
        </div>
    );
}

ProgressBarUI.propTypes = {
    progress: PropTypes.number.isRequired,
    text: PropTypes.string,
};

export default ProgressBarUI;
