import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getVkBridge } from '../../../utils/vkBridge';
import PageDefaultUI from '../../../components/Pages/Default';
import { push } from 'connected-react-router';
import {
    URL_RATING,
    URL_GAME,
    URL_SHOP,
    URL_PROFILE,
    URL_HELP,
    URL_MONEY,
} from '../../../config/url';

import { GAME_TYPE_RANDOM, GAME_TYPE_FRIEND } from '../../../config';

import {
    showInvite,
    TYPE_VK_APP,
    TYPE_WS,
    TYPE_VK,
    TYPE_FB,
    TYPE_YANDEX,
} from '../../../store/app';
import { GROUP_VK_URL, GROUP_VK_ID } from '../../../config/social';

class PageDefault extends PureComponent {
    constructor(props) {
        super(props);

        this.handleRatingPageClick = this.handleRatingPageClick.bind(this);
        this.handleShopPageClick = this.handleShopPageClick.bind(this);
        this.handleProfilePageClick = this.handleProfilePageClick.bind(this);
        this.handleRandomGamePageClick = this.handleRandomGamePageClick.bind(this);
        this.handleFriendGamePageClick = this.handleFriendGamePageClick.bind(this);
        this.handleInviteClick = this.handleInviteClick.bind(this);
        this.handleGroupClick = this.handleGroupClick.bind(this);
        this.handleHelpClick = this.handleHelpClick.bind(this);
        this.handleMoneyClick = this.handleMoneyClick.bind(this);
    }

    render() {
        const { appType } = this.props;

        return (
            <PageDefaultUI
                user={this.props.user}
                onRatingPageClick={this.handleRatingPageClick}
                onShopPageClick={
                    this.props.isBuyMoneyAllowed ? this.handleShopPageClick : undefined
                }
                onProfilePageClick={this.handleProfilePageClick}
                onRandomGamePageClick={this.handleRandomGamePageClick}
                onFriendGamePageClick={this.handleFriendGamePageClick}
                onInviteClick={
                    [TYPE_VK, TYPE_VK_APP, TYPE_FB, TYPE_WS].indexOf(appType) !== -1
                        ? this.handleInviteClick
                        : null
                }
                onlineUsersCount={this.props.onlineUsersCount}
                onGroupClick={
                    [TYPE_VK, TYPE_VK_APP, TYPE_WS].indexOf(appType) !== -1
                        ? this.handleGroupClick
                        : null
                }
                onHelpClick={[TYPE_YANDEX].indexOf(appType) !== -1 ? this.handleHelpClick : null}
                onMoneyClick={
                    this.props.isBuyMoneyAllowed && [TYPE_YANDEX].indexOf(appType) !== -1
                        ? this.handleMoneyClick
                        : null
                }
            />
        );
    }

    handleHelpClick() {
        this.props.actions.toUrl(URL_HELP);
    }

    handleRatingPageClick() {
        this.props.actions.toUrl(URL_RATING);
    }

    handleShopPageClick() {
        this.props.actions.toUrl(URL_SHOP);
    }

    handleProfilePageClick() {
        this.props.actions.toUrl(URL_PROFILE);
    }

    handleRandomGamePageClick() {
        this.props.actions.toUrl(`${URL_GAME}/${GAME_TYPE_RANDOM}`);
    }

    handleFriendGamePageClick() {
        this.props.actions.toUrl(`${URL_GAME}/${GAME_TYPE_FRIEND}`);
    }

    handleInviteClick() {
        this.props.actions.showInvite(this.props.appType);
    }

    handleGroupClick() {
        if (this.props.appType === TYPE_VK_APP) {
            getVkBridge().send('VKWebAppJoinGroup', { group_id: GROUP_VK_ID });
        } else {
            window.open(GROUP_VK_URL, '_blank');
        }
    }

    handleMoneyClick() {
        this.props.actions.toUrl(URL_MONEY);
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
        socket: state.app.socket,
        onlineUsersCount: state.app.onlineUsersCount,
        isBuyMoneyAllowed: state.app.isBuyMoneyAllowed,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
                showInvite,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageDefault);
