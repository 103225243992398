import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import GamePanelUI from '../../../../../components/Pages/Game/GamePanel';
import PlayUser from '../../../../../model/playUser';

import { EVENT_SERVER_ROUND_COMPLETED } from '../../../../../config/socket';

import { setRoundScore } from '../../../../../store/game';

class MultiplayerGamePanel extends PureComponent {
    constructor(props) {
        super(props);

        this.onRoundCompleted = this.onRoundCompleted.bind(this);
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_ROUND_COMPLETED, this.onRoundCompleted);
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_ROUND_COMPLETED, this.onRoundCompleted);
    }

    render() {
        const { playerUserStat, opponentUserStat } = this.props;

        return (
            <GamePanelUI
                roundValue={this.props.roundValue + 1}
                playerUserStat={playerUserStat}
                opponentUserStat={opponentUserStat}
                activeUserId={this.props.activeUserId}
                notification={this.props.notification}
            />
        );
    }

    /**
     *
     * @param response
     * @param response.firstUserId
     * @param response.secondUserId
     * @param response.firstUserScore
     * @param response.secondUserScore
     */
    onRoundCompleted(response) {
        this.props.actions.setRoundScore(response.firstUserId, response.firstUserScore);
        this.props.actions.setRoundScore(response.secondUserId, response.secondUserScore);
    }
}

MultiplayerGamePanel.propTypes = {
    playerUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    opponentUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    roundValue: PropTypes.number.isRequired,
    activeUserId: PropTypes.number,
    notification: PropTypes.string,
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        socket: state.app.socket,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setRoundScore,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiplayerGamePanel);
