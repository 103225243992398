import GameResultModel from '../../model/gameResult';
import UserModel from '../../model/user';
import axios from 'axios';
import { SERVER_API } from '../../config';

const CancelToken = axios.CancelToken;
let tokenLastGamesCancel;
let tokenUserCancel;

const SET_USER = Symbol('SET_USER');

export function setUser(user) {
    return {
        type: SET_USER,
        user,
    };
}

const SET_LAST_GAMES = Symbol('SET_LAST_GAMES');

export function setUserLastGames(lastGames) {
    return {
        type: SET_LAST_GAMES,
        lastGames,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

export function fetchUser(userId) {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        if (tokenUserCancel) {
            tokenUserCancel();
            tokenUserCancel = null;
        }

        return axios
            .get(`${SERVER_API}/user`, {
                cancelToken: new CancelToken((token) => {
                    tokenUserCancel = token;
                }),
                params: {
                    userId,
                },
            })
            .then(function (response) {
                tokenUserCancel = null;

                if (response.data.user) {
                    dispatch(setUser(new UserModel(response.data.user)));

                    dispatch(setIsLoading(false));
                }
            })
            .catch(() => {});
    };
}

export function fetchLastGames(userId, limit = 20) {
    if (tokenLastGamesCancel) {
        tokenLastGamesCancel();
        tokenLastGamesCancel = null;
    }

    return new Promise((resolve, reject) => {
        axios
            .get(`${SERVER_API}/user/last-games`, {
                cancelToken: new CancelToken((token) => {
                    tokenLastGamesCancel = token;
                }),
                params: {
                    userId,
                    limit,
                },
            })
            .then(
                function (response) {
                    tokenLastGamesCancel = null;

                    if (response.data.games) {
                        const games = response.data.games.map((game) => {
                            return new GameResultModel(game);
                        });

                        resolve(games);
                    } else {
                        reject(new Error('Error fetchLastGames'));
                    }
                },
                (error) => {
                    reject(error);
                }
            );
    });
}

export function fetchLastUserGames(userId, limit = 20) {
    return function (dispatch) {
        return fetchLastGames(userId, limit).then(function (games) {
            dispatch(setUserLastGames(games));
            dispatch(setIsLoading(false));
        });
    };
}

const initialState = {
    user: null,
    lastGames: null,
    isLoading: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_USER] = function () {
        return {
            ...state,
            user: action.user,
        };
    };

    exec[SET_LAST_GAMES] = function () {
        return {
            ...state,
            lastGames: action.lastGames,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
