import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogUpdateUserDetailsUI from '../../../../components/Dialog/Types/UpdateUserDetails';
import { hideDialog } from '../../../../store/dialog';
import { updateProfile, uploadPhoto } from '../../../../store/profile';

export const DIALOG_UPDATE_USER_DETAILS = 'update-user-details';

export default function DialogUpdateUserDetails({ isRequired }) {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.profile.info);

    const [isLoading, setIsLoading] = useState(false);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);

    const handleContinueClick = useCallback(
        async ({ firstName, lastName, photo }) => {
            setIsLoading(true);

            try {
                await dispatch(
                    updateProfile({
                        firstName,
                        lastName,
                    })
                );

                if (user.photoUrl !== photo) {
                    await dispatch(uploadPhoto(photo));
                }

                setIsLoading(false);
                setIsProfileUpdated(true);

                setTimeout(() => {
                    dispatch(hideDialog());
                }, 500);
            } catch (error) {
                setIsLoading(false);
                const message = (error && error.error) || 'Ошибка';
                // eslint-disable-next-line no-alert
                alert(message);
            }
        },
        [dispatch, user.photoUrl]
    );

    const handleCancelClick = useCallback(() => {
        dispatch(hideDialog());
    }, [dispatch]);

    return (
        <DialogUpdateUserDetailsUI
            onContinueClick={handleContinueClick}
            onCancelClick={handleCancelClick}
            isLoading={isLoading}
            isProfileUpdated={isProfileUpdated}
            profile={user}
            isRequired={isRequired}
        />
    );
}
