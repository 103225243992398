import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import moment from 'moment';

import GameResultModel from '../../../../model/gameResult';
import UserInfoUI from '../../../UI/UserInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faStar } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../../i18n';

function GameResultUI(props) {
    const game = props.game;
    const firstUser = game.firstPlayer;
    const secondUser = game.secondPlayer;

    const renderPoints = (user) => {
        return (
            <div className="user-game-info">
                <div className="user-name">{user.firstName}</div>
                <div className="points">
                    <i className="icon">
                        <FontAwesomeIcon icon={faStar} />
                    </i>
                    <span>{i18n.t('pages.profile.points', { count: user.points })}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="game-container">
                <div className={'game-info'} title={moment(game.date).calendar()}>
                    <div className="item date">
                        <i className="icon">
                            <FontAwesomeIcon icon={faClock} />
                        </i>
                        {moment(game.date).calendar()}
                    </div>
                    <div className="rounds">
                        {i18n.t('pages.profile.rounds', { count: game.rounds })}
                    </div>
                </div>
                <div className="game-result">
                    <div className="first-user user">
                        {renderPoints(firstUser)}
                        <UserInfoUI photoUrl={firstUser.photoUrl} type={UserInfoUI.TYPE.MEDIUM} />
                    </div>
                    <div className="score">
                        <div>
                            {firstUser.score}:{secondUser.score}
                        </div>
                    </div>
                    <div className="second-user user">
                        <UserInfoUI
                            photoUrl={secondUser.photoUrl}
                            type={UserInfoUI.TYPE.MEDIUM}
                            onClick={props.onSecondPlayerClick}
                        />
                        {renderPoints(secondUser)}
                    </div>
                </div>
            </div>
            <div className="hr" />
        </React.Fragment>
    );
}

GameResultUI.propTypes = {
    game: PropTypes.instanceOf(GameResultModel),
    onFirstPlayerClick: PropTypes.func,
    onSecondPlayerClick: PropTypes.func,
};

export default GameResultUI;
