import GameUser from './gameUser';

class GameResultModel {
    id = null;
    firstPlayer = null;
    secondPlayer = null;
    date = null;
    dateEnd = null;
    type = null;
    rounds = 0;

    constructor(gameData) {
        this.id = gameData.id;
        this.firstPlayer = new GameUser(gameData.firstPlayer);
        this.secondPlayer = new GameUser(gameData.secondPlayer);
        this.date = gameData.date;
        this.dateEnd = gameData.date_end;
        this.type = gameData.type;
        this.rounds = gameData.rounds || 0;
    }
}

export default GameResultModel;
