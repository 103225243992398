import PlayFieldItemModel from '../model/fieldItem';

export function getParameterByName(name, url) {
    // eslint-disable-next-line no-param-reassign
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isWebMobile() {
    if (typeof window !== 'undefined') {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

        return (
            (window.innerWidth <= 800 && window.innerHeight <= 600) ||
            toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            })
        );
    }

    return false;
}

/**
 * For offline mode
 *
 * @param length
 * @returns {*}
 */
export function generateItems(length) {
    function shuffle(aa) {
        for (let i = aa.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [aa[i], aa[j]] = [aa[j], aa[i]];
        }
        return aa;
    }

    const randomValuesCount = length / 2;
    const randomArray = Array.from(new Array(randomValuesCount), (val, index) => index + 1);
    const randomValues = shuffle(randomArray.concat(randomArray));

    return randomValues.map((value, i) => {
        return new PlayFieldItemModel({
            id: i,
            value,
        });
    });
}

export const numberWithSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getUserPhoto = (userPath) => {
    let imagePath;

    if (!userPath) {
        return null;
    }

    if (userPath.search('http') === -1) {
        switch (process.env.NODE_ENV) {
            case 'development':
                imagePath = `http:${userPath}`;
                break;
            default:
                imagePath = `https:${userPath}`;
        }
    } else {
        imagePath = userPath;
    }

    return imagePath;
};

export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export function loadScript(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.addEventListener('load', () => resolve(script), false);
        script.addEventListener('error', () => reject(script), false);
        document.body.appendChild(script);
    });
}
